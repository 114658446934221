import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "./../../components/UI/Loader/Loader";

// HOC
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

import JoinedRitPreview from "./../../components/Rit/JoinedRitPreview/JoinedRitPreview";

class MyRides extends Component {
  state = {
    loading: true,
    refreshedRides: false,
  };

  componentDidUpdate() {
    if (this.state.refreshedRides === false) {
      this.props.GetAllRides();
      this.setState({
        refreshedRides: true
      })
    }
  }

  componentDidMount() {
    this.props.GetAllRides();
  }

  render() {
    let ridePreviews = [];

    if (
      (Object.keys(this.props.allRides).length > 0) &
      (this.props.user !== null)
    ) {

      Object.keys(this.props.allRides).forEach(rideId => {
        if (this.props.allRides[rideId].riders !== undefined) {
          if (this.props.userId in this.props.allRides[rideId].riders) {
            ridePreviews.push(
              <JoinedRitPreview
                key={rideId}
                userId={this.props.userId}
                ride={this.props.allRides[rideId]}
              />
            );
          }
        } else {
          if (this.props.allRides[rideId].host.id === this.props.userId) {
            ridePreviews.push(
              <JoinedRitPreview
                key={rideId}
                userId={this.props.userId}
                ride={this.props.allRides[rideId]}
              />
            );
          }
        }
      });
    }

    return (
      <div className="section cc-home-wrap">
        <div className="heading-jumbo">
          Jouw ritten
          <br />
        </div>
        <div className="container">
          <div className="motto-wrap" />
          <div className="divider" />
          <div className="about-story-wrap">
            <p className="paragraph-light">
              Dit is een overzicht van alle ritten waar je nog voor ingeschreven
              staat of die je al hebt gereden
            </p>
            <ul className="w-list-unstyled">
              {Object.keys(this.props.allRides).length > 0 ? (
                ridePreviews.length > 0 ? (
                  ridePreviews.map(ridePreview => {
                    return ridePreview;
                  })
                ) : (
                    "Je hebt je bij nog geen ritten aangemeld"
                  )
              ) : (
                  <Loader />
                )}
            </ul>
          </div>
          <div className="divider" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allRides: state.ritten.currentRides,
    currentlyLoading: state.ritten.currentlyLoading,
    userId: state.users.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(MyRides, axios));
