
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';

// HOC
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";
import Loader from './../../../components/UI/Loader/Loader';

class RitWijzigen extends Component {
    state = {
        ride: null
    }

    componentDidUpdate() {
        if (this.state.ride === null & this.props.selectedRide !== null) {
            this.setState({ ride: { ...this.props.selectedRide } })
        }
    }

    componentDidMount() {
        const { rideId } = this.props.match.params;
        this.props.GetRideDetails(rideId)
    }

    changeRideDetails = e => {
        let ride = { ...this.state.ride }

        if (e.target.name === 'date' | e.target.name === 'time') {
            ride.departure[e.target.name] = e.target.value;
        } else if (e.target.name === 'street' | e.target.name === 'city') {
            ride.departure.location[e.target.name] = e.target.value;
        } else if (e.target.name === 'routeUrl') {
            ride.stravaDetails[e.target.name] = e.target.value;
        } else {
            // Set regular property
            ride[e.target.name] = e.target.value;
        }

        this.setState({ ride: ride })
    }

    updateRide = (e) => {
        e.preventDefault();
        this.props.updateRide(this.state.ride);
        NotificationManager.success('We hebben je rit bijgewerkt', 'Succesvol geupdatet!');
    }

    render() {
        return (
            this.state.ride !== null ?
                (
                    <div className="section cc-home-wrap">
                        <div className="heading-jumbo">Rit wijzigen<br /></div>
                        <div className="container">
                            <div className="motto-wrap"></div>
                            <div className="divider"></div>
                            <div className="about-story-wrap"></div>
                            <div className="div-block">
                                <h2 className="contact-heading">Rondje loosdrechtse plassen</h2>
                                <div className="paragraph-light">Verander hieronder de velden en druk op opslaan om je rit aan te passen. </div>
                                <div className="contact-form w-form">
                                    <div data-name="Get In Touch Form" name="wf-form-Get-In-Touch-Form" className="get-in-touch-form">
                                        <label for="name">TITEL</label>
                                        <input
                                            type="text"
                                            className="text-field cc-contact-field w-input"
                                            maxLength="256"
                                            name="name"
                                            onChange={(e) => this.changeRideDetails(e)}
                                            value={this.state.ride.name}
                                            placeholder="Rondje loosdrechtse plassen"
                                            required />
                                        <label for="name-3">Waar kunnen mensen je aan herkennen?</label>
                                        <input
                                            type="text"
                                            className="text-field cc-contact-field w-input"
                                            maxLength="256"
                                            name="recogniseMeBy"
                                            onChange={(e) => this.changeRideDetails(e)}
                                            value={this.state.ride.recogniseMeBy}
                                            placeholder="Canyon rood - Shirt van Amstel gold Race - Gele helm"
                                            required />
                                        <div>
                                            <div className="w-row">
                                                <div className="column w-col w-col-6">
                                                    <label for="Email">VERTREKDATUM</label>
                                                    <input
                                                        type="text"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="date"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.departure.date}
                                                        placeholder="09-05-2019" />
                                                </div>
                                                <div className="column-2 w-col w-col-6">
                                                    <label for="Email">VERTREKTIJD</label>
                                                    <input
                                                        type="time"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="time"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.departure.time}
                                                        placeholder="11:30"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-row">
                                                <div className="column w-col w-col-6">
                                                    <label for="Email-3">VERTREK ADRES</label>
                                                    <input
                                                        type="text"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="street"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.departure.location.street}
                                                        placeholder="Uithoff 13"
                                                    />
                                                </div>
                                                <div className="column-2 w-col w-col-6">
                                                    <label for="Email-3">STAD</label>
                                                    <input
                                                        type="text"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="city"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.departure.location.city}
                                                        placeholder="Utrecht"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-row">
                                                <div className="column w-col w-col-6">
                                                    <label for="Email">AFSTAND</label>
                                                    <input
                                                        type="number"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="distance"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.distance}
                                                        placeholder="50" />
                                                </div>
                                                <div className="column-3 w-col w-col-6">
                                                    <label for="Email">GEMIDDELDE SNELHEID</label>
                                                    <input
                                                        type="number"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="averagePace"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.averagePace}
                                                        placeholder="30" />
                                                </div>
                                            </div>
                                            <div className="w-row">
                                                <div className="column w-col w-col-6">
                                                    <label for="afstand">MAXIMAAL AANTAL DEELNEMERS</label>
                                                    <input
                                                        type="number"
                                                        className="text-field cc-contact-field w-input"
                                                        maxLength="256"
                                                        name="maxAmountOfRiders"
                                                        onChange={(e) => this.changeRideDetails(e)}
                                                        value={this.state.ride.maxAmountOfRiders}
                                                        placeholder="20" />
                                                </div>
                                                <div className="column-4 w-col w-col-6"></div>
                                            </div>
                                            <label for="name-3">STRAVA ROUTE LINK</label>
                                            <input
                                                type="text"
                                                className="text-field cc-contact-field w-input"
                                                maxLength="256"
                                                name="routeUrl"
                                                onChange={(e) => this.changeRideDetails(e)}
                                                value={this.state.ride.stravaDetails.routeUrl}
                                                placeholder="https://www.strava.com/route/rondje-loosdrechtse-plassen" />
                                        </div>
                                        <NotificationContainer />
                                        <Loader loading={this.props.loading} />

                                        <button
                                            type="submit"
                                            onClick={(e) => this.updateRide(e)}
                                            className="button w-button">
                                            OPSLAAN
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <Loader />
        )
    }
}

const mapStateToProps = state => {
    return {
        currentlyLoading: state.ritten.currentlyLoading,
        blogs: state.blogs.allBlogs,
        selectedRide: state.ritten.selectedRide,
        loading: state.ritten.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GetRideDetails: (rideId) => dispatch(actions.GetRideDetails(rideId)),
        updateRide: (ride) => dispatch(actions.updateRide(ride))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(RitWijzigen, axios));
