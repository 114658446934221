import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const GetAllUsers = () => {
  return {
    type: actionTypes.GET_ALL_USERS
  };
};

export const Logout = () => {
  return {
    type: actionTypes.LOGOUT
  };
};

export const authSuccess = (token, userId, userMeta) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    userMeta: userMeta.data
  };
};

export const registrationSuccess = (token, userId, userMeta) => {
  return {
    type: actionTypes.REGISTRATION_SUCCESS,
    idToken: token,
    userId: userId,
    userMeta: userMeta.data
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const setEntryPoint = entryPointUrl => {
  return {
    type: actionTypes.SET_ENTRY_POINT,
    entryPoint: entryPointUrl
  };
};

export const auth = (email, password, user, isSignup) => {
  return dispatch => {
    dispatch(authStart());

    const authData = {
      email: email,
      password: password,
      returnSecureToken: true
    };

    let url =
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyC92GBc1DmtaIOWdmGo6sUsucktbKiHlis";

    if (!isSignup) {
      url =
        "https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyC92GBc1DmtaIOWdmGo6sUsucktbKiHlis";
    }

    axios
      .post(url, authData)
      .then(response => {
        if (isSignup) {
          const newUser = {
            ...user,
            id: response.data.localId,
            role: 0
          };

          delete newUser.password;

          axios.put(
            "https://tgtr-6b72e.firebaseio.com/users/" +
            response.data.localId +
            ".json",
            newUser
          );
        }

        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );
        localStorage.setItem("token", response.data.idToken);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("userId", response.data.localId);
        axios
          .get(
            "https://tgtr-6b72e.firebaseio.com/users/" +
            response.data.localId +
            ".json"
          )
          .then(userMetaResponse => {
            if (isSignup) {
              dispatch(
                registrationSuccess(
                  response.data.idToken,
                  response.data.localId,
                  userMetaResponse
                )
              );
            } else {
              dispatch(
                authSuccess(
                  response.data.idToken,
                  response.data.localId,
                  userMetaResponse
                )
              );
            }

            dispatch(checkAuthTimeout(response.data.expiresIn));
          });
      })
      .catch(err => {
        console.log(err);
        dispatch(authFail(err));
      });
  };
};

const userUpdateSuccesfully = user => {
  return {
    type: actionTypes.USER_UPDATE_SUCCESS,
    user: user
  };
};

const initUserUpdate = () => {
  return {
    type: actionTypes.USER_UPDATE_INIT
  };
};

export const updateUser = user => {
  return dispatch => {
    dispatch(initUserUpdate());
    axios
      .put("https://tgtr-6b72e.firebaseio.com/users/" + user.id + ".json", user)
      .then(response => {
        dispatch(userUpdateSuccesfully(user));
      });
  };
};

const initGetUserProfile = userId => {
  return {
    type: actionTypes.INIT_GET_USER
  };
};

const getUserProfileSuccess = user => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user: user
  };
};

export const getUserProfile = userId => {
  return dispatch => {
    dispatch(initGetUserProfile());
    axios
      .get("https://tgtr-6b72e.firebaseio.com/users/" + userId + ".json")
      .then(response => {
        dispatch(getUserProfileSuccess(response.data));
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("userId");
        axios
          .get("https://tgtr-6b72e.firebaseio.com/users/" + userId + ".json")
          .then(userMetaResponse => {
            dispatch(authSuccess(token, userId, userMetaResponse));
            dispatch(
              checkAuthTimeout(
                (expirationDate.getTime() - new Date().getTime()) / 1000
              )
            );
          });
      }
    }
  };
};

const getDetailsForUsers = users => {
  return dispatch => {
    const friends = { ...users };
    let friendsObjects = {};
    const totalAmountOfFriends = Object.keys(friends).length;
    let retrievedFriendsDetails = 0;

    Object.keys(friends).forEach(friendsId => {
      axios
        .get("https://tgtr-6b72e.firebaseio.com/users/" + friendsId + ".json")
        .then(friendDetails => {
          if (friendDetails.data !== null) {
            friendsObjects[friendDetails.data.id] = friendDetails.data;
            retrievedFriendsDetails++;
            if (retrievedFriendsDetails === totalAmountOfFriends) {
              dispatch(getFriendsDetailsSuccess(friendsObjects));
            }
          } else {
            retrievedFriendsDetails++;
          }
        });
    });
  };
};

const initGetFriendsDetails = () => {
  return {
    type: actionTypes.INIT_GET_FRIENDS_DETAILS
  };
};

const getFriendsDetailsSuccess = friends => {
  return {
    type: actionTypes.GET_FRIENDS_DETAILS_SUCCESS,
    friends: friends
  };
};

export const initInviteFriend = () => {
  return {
    type: actionTypes.INIT_INVITE_FRIEND
  };
};

const searchUsersByEmailSuccess = usersList => {
  return {
    type: actionTypes.SEARCH_USER_BY_EMAIL_SUCCESS,
    foundUsersList: usersList
  };
};

const initSearchUsersByEmail = () => {
  return {
    type: actionTypes.INIT_SEARCH_USER_BY_EMAIL
  };
};

export const searchUsersByEmail = email => {
  return dispatch => {
    dispatch(initSearchUsersByEmail());
    axios
      .get("https://tgtr-6b72e.firebaseio.com/users.json")
      .then(usersData => {
        let users = { ...usersData.data };
        let usersList = [];
        Object.keys(users).forEach(userId => {
          let user = { ...users[userId] };
          if (user.email === email) {
            usersList.push(user);
          }
        });
        dispatch(searchUsersByEmailSuccess(usersList));
      });
  };
};

const inviteFriendSuccess = user => {
  return {
    type: actionTypes.INVITE_FRIEND_SUCCESS,
    user: user
  };
};

export const inviteFriend = (user, requestFriend) => {
  return dispatch => {
    dispatch(initInviteFriend());
    axios
      .get(
        "https://tgtr-6b72e.firebaseio.com/users/" + requestFriend.id + ".json"
      )
      .then(friend => {
        // Add current user to openFriendsRequests
        let requestedFriend = { ...friend.data };
        delete user.openFriendRequests;
        delete user.friends;
        if ("openFriendRequests" in requestedFriend) {
          if (user.id in requestedFriend.openFriendRequests === false) {
            requestedFriend.openFriendRequests = { ...requestedFriend.openFriendRequests, [user.id]: user }
          }
        } else {
          requestedFriend["openFriendRequests"] = { [user.id]: user };
        }

        axios
          .put(
            "https://tgtr-6b72e.firebaseio.com/users/" +
            requestedFriend.id +
            ".json",
            requestedFriend
          )
          .then(updatedUser => {
            // Sent out success message
            dispatch(inviteFriendSuccess(updatedUser.data));
          }).catch(error => {
            console.log('Error when inviting friend: ' + error)
          });
      });
  };
};

const acceptFriendRequestSuccess = user => {
  return {
    type: actionTypes.ACCEPT_FRIEND_REQUEST_SUCCESS,
    user: user
  }
}

const initAcceptFriendRequest = () => {
  return {
    type: actionTypes.INIT_ACCEPT_FRIEND_REQUEST
  }
}

export const acceptFriendRequest = (user, friendRequest) => {
  return dispatch => {
    dispatch(initAcceptFriendRequest());

    //  Get current user details: Remove from requests and add to friends
    axios.get('https://tgtr-6b72e.firebaseio.com/users/' + user.id + '.json').then(userData => {
      let refreshedUser = { ...userData.data }
      delete refreshedUser.openFriendRequests[friendRequest.id]

      if ('friends' in refreshedUser) {
        refreshedUser.friends[friendRequest.id] = friendRequest.id
      } else {
        refreshedUser.friends = { [friendRequest.id]: friendRequest.id }
      }

      axios.get('https://tgtr-6b72e.firebaseio.com/users/' + friendRequest.id + '.json').then(friendRequestData => {
        let refreshedRequester = { ...friendRequestData.data }
        if ('friends' in refreshedRequester) {
          refreshedRequester.friends[refreshedUser.id] = refreshedUser.id
        } else {
          refreshedRequester.friends = { [refreshedUser.id]: refreshedUser.id }
        }

        // Update both users
        axios.put('https://tgtr-6b72e.firebaseio.com/users/' + refreshedRequester.id + '.json', refreshedRequester).then(refreshedRequesterResponse => {
          axios.put('https://tgtr-6b72e.firebaseio.com/users/' + refreshedUser.id + '.json', refreshedUser).then(refreshedUserResponse => {
            console.log(refreshedRequesterResponse, refreshedUserResponse)
            dispatch(acceptFriendRequestSuccess(refreshedUser))
          })
        });
      });
    });
  }
}

export const getFriendsDetailsForUser = userId => {
  /* eslint-disable no-unused-expressions */
  return dispatch => {
    axios
      .get(
        "https://tgtr-6b72e.firebaseio.com/users/" + userId + "/friends.json"
      )
      .then(friendsResponse => {
        dispatch(getDetailsForUsers(friendsResponse.data));
      });

    dispatch(initGetFriendsDetails());
  };
};
