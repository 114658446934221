import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./store/actions/index";

import Layout from "./hoc/Layout/Layout";
import Homepage from "./containers/Homepage/Homepage";
import Over from "./containers/Over/Over";
import Blogs from "./containers/Blogs/Blogs";

// Ritten specific containers
import Ritten from "./containers/Ritten/Ritten";
import RittenVrienden from './containers/Ritten/RittenVrienden/RittenVrienden';
import RitMaken from "./containers/Ritten/RitMaken/RitMaken";
import RitDetails from './containers/Ritten/RitDetails/RitDetails';
import RitWijzigen from './containers/Ritten/RitWijzigen/RitWijzigen';

// User specific containres
import Auth from "./containers/Auth/Auth";
import Logout from "./containers/Auth/Logout/Logout";
import Register from './containers/Auth/Register/Register';
import MyProfile from './containers/MyProfile/MyProfile';
import MyRides from './containers/MyProfile/MyRides';
import MyFriends from './containers//MyProfile/MyFriends';
import Profile from './containers/Profile/Profile';

import Contact from "./containers/Contact/Contact";
import NoMatch from "./containers/NoMatch/NoMatch";

class App extends Component {

  componentDidMount() {
    this.props.onTryAutoSignUp();
  }

  render() {
    let routes = (
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/over-ons" component={Over} />
        <Route exact path="/ritten" component={Auth} />
        <Route path="/rit/:rideId" component={Auth} />
        <Route path="/rit-wijzigen/:rideId" component={RitWijzigen} />
        <Route path="/rit-maken" component={Auth} />
        <Route path="/blog" component={Blogs} />
        <Route path="/contact" component={Contact} />
        <Route path="/login" component={Auth} />
        <Route path="/logout" component={Logout} />
        <Route path="/register" component={Register} />
        <Route path='/mijn-profiel' component={Auth} />
        <Route path='/profiel/:userId' component={Profile} />
        <Route path='/mijn-ritten' component={Auth} />
        <Route path='/vrienden-vinden' component={Auth} />
        <Route component={NoMatch} />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/over-ons" component={Over} />
          <Route exact path="/ritten" component={Ritten} />
          <Route exact path="/ritten-van-vrienden" component={RittenVrienden} />
          <Route path="/rit/:rideId" component={RitDetails} />
          <Route path="/rit-wijzigen/:rideId" component={RitWijzigen} />
          <Route path="/rit-maken" component={RitMaken} />
          <Route path="/blog" component={Blogs} />
          <Route path="/contact" component={Contact} />
          <Route path="/login" component={Auth} />
          <Route path="/logout" component={Logout} />
          {this.props.registrationCompleted ? <Route path="/register" component={Register} /> : <Route path="/register" component={MyProfile} />}
          <Route path='/mijn-profiel' component={MyProfile} />
          <Route path='/vrienden-vinden' component={MyFriends} />
          <Route path='/profiel/:userId' component={Profile} />
          <Route path='/mijn-ritten' component={MyRides} />
          <Route component={NoMatch} />
        </Switch>
      )
    }

    return (
      <div>
        <Layout>{routes}</Layout>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    isAuthenticated: state.users.token !== null,
    registrationCompleted: state.users.registrationSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides()),
    onTryAutoSignUp: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
