import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

// HOC
import Aux from './../../hoc/Auxo/Auxo';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

class Over extends Component {
    state = {}

    render() {
        return (
            <Aux>
                <div className="section cc-home-wrap">
                    <div className="intro-header cc-subpage">
                        <div className="intro-content">
                            <div className="heading-jumbo">Over Ons<br /></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="motto-wrap">
                            <div className="heading-jumbo-small">
                                Wij zijn een groep fietsers die liever in een groep fietsen dan alleen, maar die niet willen inleveren op vaste dagen en uren.</div>
                        </div>
                        <div className="divider"></div>
                        <div className="about-story-wrap">
                            <p className="paragraph-light">
                                Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. <br />‍<br />Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar. The Big Oxmox advised her not to do so, because there were thousands of bad Commas, wild Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. <br />‍<br />She packed her seven versalia, put her initial into the belt and made herself on the way. When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own road, the Line Lane. </p>
                        </div>
                        <div className="divider"></div>
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentlyLoading: state.ritten.currentlyLoading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        GetAllRides: () => dispatch(actions.GetAllRides())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Over, axios));
