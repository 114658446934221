import React from "react";
import { NavLink } from "react-router-dom";
import { FaUser, FaStrava } from "react-icons/fa";

// UI avatars
import ManAvatar from "./../../../assets/images/boy.png";
import RatingStar from "./../../../assets/images/star.png";

const UserPreview = props => {
  return (
    <div className="w-row">
      <div className="w-col w-col-1">
        <img src={ManAvatar} width="104" alt="" className="image-10" />
      </div>
      <div className="w-col w-col-3">
        {props.user.firstName !== "" ? (
          <h6>
            {props.user.firstName} {props.user.lastName}
          </h6>
        ) : (
            <h6>Rijder zonder naam</h6>
          )}
      </div>
      {props.user.score > 0 ? (
        <div className="w-col w-col-3">
          <div className="div-block-3 w-clearfix">
            <img src={RatingStar} alt="" className="image-7" />
            <img src={RatingStar} alt="" className="image-7" />
            <img src={RatingStar} alt="" className="image-7" />
            <img src={RatingStar} alt="" className="image-7" />
            <img src={RatingStar} alt="" className="image-7" />
          </div>
        </div>
      ) : null}

      {props.user.ridesHistory ? (
        <div className="w-col w-col-2">
          <p>{props.user.ridesHistory.length} ritten gereden</p>
        </div>
      ) : null}

      <div className="w-col w-col-2">
        <NavLink to={"/profiel/" + props.user.id}>
          <FaUser />
        </NavLink>
      </div>
      {props.user.stravaprofile ? (
        <div className="w-col w-col-1">
          <a href="/login" className="link">
            <FaStrava />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default UserPreview;
