import React, { Component } from "react";

import NotFoundImage from "./../../assets/images/404.png";

class NoMatch extends Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="w-layout-grid contact-form-grid">
            <div
              id="w-node-509be705858e-01d321b3"
              className="contact-form-wrap"
            >
              <div className="contact-form-heading-wrap">
                <h2 className="contact-heading">Deze pagina is er vandoor</h2>
                <div className="paragraph-light">
                  Ga terug naar de homepagina
                </div>
              </div>
              <img src={NotFoundImage} alt="" className="image-3" />
            </div>
            <div id="w-node-509be70585a6-01d321b3">
              <div className="details-wrap">
                <div className="label">Informatie</div>
                <div className="paragraph-light">
                  Om een zo prettig mogelijke rit voor iedereen te kunnen
                  garanderen zijn er wat basis regels opgesteld.
                </div>
              </div>
              <div className="details-wrap">
                <div className="label">Huisregels</div>
                <div className="paragraph-light">
                  - Er is 5 minuten uitloopt waarna de groep altijd vertrekt.
                  <br />- De groep wacht op ieder, tenzij anders aangegeven
                  <br />- Zorg dat je vervelende fietsers aangeeft op het
                  platform, zodat we het schoon kunnen houden
                </div>
              </div>
              <div className="details-wrap">
                <div className="label">Vragen</div>
                <div className="paragraph-light">
                  Heb je nog vragen en/of opmerkingen?
                </div>
                <a
                  href="mailto:contact@business.com?subject=You&#x27;ve%20got%20mail!"
                  className="contact-email-link"
                >
                  vragen@tgtr.nl
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoMatch;
