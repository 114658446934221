
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from './../../components/UI/Loader/Loader';

// HOC
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

// UI
import ManAvatar from './../../assets/images/boy.png';
import RatingStar from './../../assets/images/star.png'
class Profile extends Component {
    state = {
        userProfile: null
    }

    componentDidUpdate() {
        if (this.state.userProfile === null & this.props.publicProfileUser !== null) {
            this.setState({
                userProfile: { ...this.props.publicProfileUser }
            })
        }

        if (this.state.userProfile !== null & this.props.publicProfileUser !== null) {
            if (this.state.userProfile.id !== this.props.publicProfileUser.id) {
                this.setState({
                    userProfile: { ...this.props.publicProfileUser }
                })
            }
        }

    }

    componentDidMount() {
        // Get user details from URL parameters and call on GetUserDetails(userId) to retrieve the profile information
        const { userId } = this.props.match.params;
        this.props.GetUserProfile(userId)
    }

    render() {
        return (
            this.state.userProfile !== null ? (
                <div className="section cc-home-wrap">
                    <div className="container">
                        <div className="motto-wrap">
                            <div className="heading-jumbo-small">{this.state.userProfile.firstName} {this.state.userProfile.lastName}</div>
                        </div>
                        <div className="divider"></div>
                        <div className="w-row">
                            <div className="w-col w-col-3"><img src={ManAvatar} alt="" /></div>
                            <div className="w-col w-col-9">
                                <h6>Over mij</h6>
                                <p className="paragraph-light">
                                    {this.state.userProfile.aboutme}
                                </p>
                                <h6>Wat de statistieken zeggen</h6>
                                <ul className="list-2">
                                    <li>
                                        <p className="paragraph-light">Gemiddelde afstand: {this.state.userProfile.averageDistance} kilometer<br /></p>
                                    </li>
                                    <li>
                                        <p className="paragraph-light">Gemiddelde snelheid: {this.state.userProfile.averagePace} kilometer per uur<br /></p>
                                    </li>
                                    {this.state.userProfile.rideHistory ?
                                        <li>
                                            <p className="paragraph-light">{this.state.userProfile.rideHistory.length} ritten gereden op TGTR<br /></p>
                                        </li>
                                        : null}
                                </ul>

                                {this.state.userProfile.reviews ?
                                    <div>
                                        <h6>Wat anderen zeggen</h6>
                                        <ul className="list-2">
                                            <li>
                                                <p className="paragraph-light">Prima fietser, keurig op tijd en ervaren<br /></p>
                                            </li>
                                            <li>
                                                <p className="paragraph-light">Gezellige vent<br /></p>
                                            </li>
                                            <li>
                                                <p className="paragraph-light">Goede fietser<br /></p>
                                            </li>
                                        </ul>
                                    </div>
                                    : null}

                                {this.state.userProfile.score > 0 ?
                                    <div>
                                        <h6>Mijn score</h6>
                                        <div className="w-clearfix">
                                            <img src={RatingStar} alt="" className="image-9" />
                                            <img src={RatingStar} alt="" className="image-9" />
                                            <img src={RatingStar} alt="" className="image-9" />
                                            <img src={RatingStar} alt="" className="image-9" />
                                            <img src={RatingStar} alt="" className="image-9" />
                                        </div>
                                    </div>
                                    : null}
                                <div><a href="/login" className="button-3 w-button">Strava profiel</a></div>
                            </div>
                        </div>
                        <div className="about-story-wrap"></div>
                        <div className="divider"></div>
                    </div>
                </div>
            ) : <Loader />
        );
    }
}

const mapStateToProps = state => {
    return {
        currentlyLoading: state.ritten.currentlyLoading,
        publicProfileUser: state.users.publicProfileUser,
        blogs: state.blogs.allBlogs
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GetAllRides: () => dispatch(actions.GetAllRides()),
        GetUserProfile: (userId) => dispatch(actions.getUserProfile(userId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(Profile, axios));
