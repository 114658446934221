import { Component } from 'react';
import { withRouter } from 'react-router-dom';

const EXCLUDE_FROM_SCROLLING = [
  'rit',
  'ritten'
]
class ScrollToTop extends Component {

  componentDidUpdate(prevProps) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth;

    if (this.props.location !== prevProps.location & !EXCLUDE_FROM_SCROLLING.includes(this.props.location.pathname.split("/")[1])) {
      if (x > 500) {
        window.scrollTo(0, 125)
      } else {
        window.scrollTo(0, 250)
      }
    } else {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)