import React from 'react';
import { NavLink } from "react-router-dom";

const RegistrationSuccess = props => {
    return (
        <div className="section">
            <div className="intro-header cc-subpage">
                <div className="intro-content">
                    <div className="heading-jumbo">
                        Welkom bij de club{props.user.firstName.length > 3 ? ', ' + props.user.firstName : null}
                        <br />
                    </div>
                </div>
            </div>

            <div className="div-block">
                <h2 className="contact-heading">Leuk dat je met ons wilt rijden</h2>
                <div className="paragraph-light">
                    Je hebt in je email ({props.user.email}) een bevestiging van je aanmelding. Zou je daar ook je e-mail adres willen bevestigen? <br />
                    <br />
                    Wanneer je je email adres hebt bevestigd kun je via de onderstaande link je eerste ritten gaan zoeken
                    <NavLink to="/ritten">Klik hier om, na je e-mail bevestiging, ritten te gaan zoeken</NavLink>
                </div>
            </div>
        </div>
    )
}

export default RegistrationSuccess;