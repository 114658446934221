import React, { Component } from 'react';

// HOC
import Aux from './../../hoc/Auxo/Auxo';

class Contact extends Component {
    render() {
        return (
            < Aux >
                < div className="section" >
                    <div className="intro-header cc-subpage">
                        <div className="intro-content">
                            <div className="heading-jumbo">Contact<br /></div>
                        </div>
                    </div>
                </div >
                <div className="section">
                    <div className="container">
                        <div className="w-layout-grid contact-htmlForm-grid">
                            <div id="w-node-509be705858e-5e5bfbee" className="contact-htmlForm-wrap">
                                <div className="contact-htmlForm-heading-wrap">
                                    <h2 className="contact-heading">Contact</h2>
                                    <div className="paragraph-light">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave htmlFor the far World of Grammar.</div>
                                </div>
                                <div className="contact-htmlForm w-htmlForm">
                                    <div data-name="Get In Touch htmlForm" name="wf-htmlForm-Get-In-Touch-htmlForm" className="get-in-touch-htmlForm">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" className="text-field cc-contact-field w-input" maxLength="256" name="name" data-name="Name" placeholder="Jouw naam" id="Name" />
                                        <label htmlFor="Email-2">Email Address</label>
                                        <input type="email" className="text-field cc-contact-field w-input" maxLength="256" name="Email" data-name="Email" placeholder="Jouw e-mail" id="Email" required="" />
                                        <label htmlFor="Message">Message</label><textarea id="Message" name="Message" placeholder="Hoi, ik had een vraag over…" maxLength="5000" data-name="Message" className="text-field cc-textarea cc-contact-field w-input"></textarea>
                                        <input type="submit" value="Versturen" data-wait="Please wait..." className="button w-button" />
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-509be70585a6-5e5bfbee">
                                <div className="details-wrap">
                                    <div className="label">Our offices </div>
                                    <div className="paragraph-light">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.</div>
                                </div>
                                <div className="details-wrap">
                                    <div className="label">OUR OFFICE</div>
                                    <div className="paragraph-light">908 Br/oadway, <br />San Francisco,<br />CA 94133</div>
                                </div>
                                <div className="details-wrap">
                                    <div className="label">WORKING HOURS</div>
                                    <div className="paragraph-light">9AM - 3PM, Mon to Fri</div>
                                </div>
                                <div className="details-wrap">
                                    <div className="label">CONTACT</div><a href="mailto:contact@business.com?subject=You&#x27;ve%20got%20mail!" className="contact-email-link">contact@business.com</a>
                                    <div className="paragraph-light">01 (650) 658 6822</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }

}

export default Contact;