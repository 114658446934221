import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { NavLink } from "react-router-dom";
import scrollToComponent from "react-scroll-to-component";
import Loader from './../../../components/UI/Loader/Loader';

// HOC
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";

// UI
import './Register.css';
import RegistrationSuccess from './../../../components/User/RegistrationSuccess/RegistrationSuccess';

// const ZIPCODE_REGEX = ([0-9][0-9][0-9][0-9]\s[A-Z][A-Z])
class Register extends Component {
  state = {
    registrationState: 0,
    user: {
      aboutme: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      address: {
        street: "",
        streetNumber: "",
        zipcode: "",
        city: ""
      },
      averagePace: 0,
      averageDistance: 0,
      stravaprofile: "",
      mobilephone: "",
      contactPreferences: {
        mailsAboutRides: false,
        newsletters: false
      },
      acceptedtoc: false
    }
  };

  continueRegistrationClicked = event => {
    event.preventDefault();
    let currentRegistrationStep = this.state.registrationState;
    if (currentRegistrationStep === 0) {
      // Scroll to scene 1: firstOptionalFields
      scrollToComponent(this.firstOptionalFields, {
        offset: 0,
        align: "top",
        duration: 500,
        ease: "inCirc"
      });
    } else if (currentRegistrationStep === 1) {
      // Scroll to scene 2
      scrollToComponent(this.secondOptionalFields, {
        offset: 0,
        align: "top",
        duration: 500,
        ease: "inCirc"
      });
    } else if (currentRegistrationStep === 2) {
      // Scroll to scene 3
      scrollToComponent(this.thirdOptionalFields, {
        offset: 0,
        align: "top",
        duration: 500,
        ease: "inCirc"
      });
    } else if (currentRegistrationStep === 3) {
      scrollToComponent(this.fourthOptionalFields, {
        offset: 0,
        align: "top",
        duration: 500,
        ease: "inCirc"
      });
    }

    this.setState({
      registrationState: currentRegistrationStep + 1
    });
  };

  validateEmail = email => {
    // eslint-disable-next-line
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  registrationFieldChanged = e => {
    let user = { ...this.state.user };
    if (
      (e.target.name === "street") |
      (e.target.name === "streetNumber") |
      (e.target.name === "zipcode") |
      (e.target.name === "city")
    ) {
      user.address[e.target.name] = e.target.value;
    } else if (
      (e.target.name === "mailsAboutRides") |
      (e.target.name === "newsletters")
    ) {
      user.contactPreferences[e.target.name] = !user.contactPreferences[
        e.target.name
      ];
    } else if (
      e.target.name === 'acceptedtoc'
    ) {
      user[e.target.name] = !user[
        e.target.name
      ];
    } else {
      user[e.target.name] = e.target.value;
    }

    // Validate user email adres
    this.setState({
      user: user,
      validEmail: this.validateEmail(user.email)
    });
  };

  registerClicked = () => {
    this.props.Register(
      this.state.user.email,
      this.state.user.password,
      this.state.user,
      true
    );
  };

  render() {
    let optionalPersonRegistrationFields = (
      <div>
        <h3>Optioneel</h3>
        <div className="paragraph-light">
          Deze velden worden alleen gebruikt zodat andere rijders makkelijker
          contact met je kunnen opnemen of beter jouw rij kwaliteiten kunnen
          inschatten. Ze zijn volledig optioneel en je kunt, mocht je dat
          willen, dan ook alles vanaf nu overslaan. <br />
          <br />
          Wij raden aan om wel je mobiele nummer, gemiddelde snelheid en afstand
          in te vullen.
        </div>
        <div
          className="w-row"
          ref={section => {
            this.firstOptionalFields = section;
          }}>
          <div className="column w-col w-col-6">
            <label htmlFor="Email-3">Voornaam</label>
            <input
              type="text"
              className="text-field cc-contact-field w-input"
              maxLength="40"
              value={this.state.user.firstName}
              onChange={this.registrationFieldChanged}
              name="firstName"
              placeholder="Jan"
            />
          </div>
          <div className="column-2 w-col w-col-6">
            <label htmlFor="Email-3">ACHTERNAAM</label>
            <input
              type="text"
              className="text-field cc-contact-field w-input"
              maxLength="40"
              value={this.state.user.lastName}
              onChange={this.registrationFieldChanged}
              name="lastName"
              placeholder="Van Hendriks"
            />
          </div>
        </div>
        <div className="w-row">
          <div className="column w-col w-col-6">
            <label htmlFor="Email-3">POSTCODE</label>
            <input
              type="text"
              className="text-field cc-contact-field w-input"
              maxLength="40"
              value={this.state.user.address.zipcode}
              onChange={this.registrationFieldChanged}
              name="zipcode"
              placeholder="3551 ET"
            />
          </div>
          <div className="column-3 w-col w-col-6">
            <label htmlFor="Email-3">STAD</label>
            <input
              type="text"
              className="text-field cc-contact-field w-input"
              maxLength="40"
              value={this.state.user.address.city}
              onChange={this.registrationFieldChanged}
              name="city"
              placeholder="Utrecht"
            />
          </div>
        </div>
      </div>
    );

    let optionalRidersDetails = (
      <div>
        <div className="w-row">
          <div className="column w-col w-col-6">
            <label htmlFor="afstand">Gemiddelde afstand</label>
            <input
              type="number"
              className="text-field cc-contact-field w-input"
              maxLength="140"
              value={this.state.user.averageDistance}
              onChange={this.registrationFieldChanged}
              name="averageDistance"
              placeholder="50"
            />
          </div>
          <div className="column-4 w-col w-col-6">
            <label htmlFor="snelheid">gemiddelde snelheid</label>
            <input
              type="number"
              className="text-field cc-contact-field w-input"
              value={this.state.user.averagePace}
              onChange={this.registrationFieldChanged}
              name="averagePace"
              placeholder="30"
            />
          </div>
        </div>
        <div id="email-form" name="email-form" data-name="Email Form">
          <label htmlFor="strava">Over mij</label>
          <textarea
            name="aboutme"
            onChange={this.registrationFieldChanged}
            value={this.state.user.aboutme}
            placeholder="Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. "
            maxLength="5000"
            class="w-input">
          </textarea>
        </div>
        <label htmlFor="strava">STRAVA PROFIEL</label>
        <input
          type="text"
          className="text-field cc-contact-field w-input"
          value={this.state.user.stravaprofile}
          onChange={this.registrationFieldChanged}
          name="stravaprofile"
          placeholder="https://www.strava.com/user/janvanhendriks"
        />
        <label htmlFor="Email-4">Mobiel nummer</label>
        <div className="paragraph-light">
          De organisator van ritten kan, bij vertraging of incidenten, eventueel
          contact met je opnemen voor of tijdens de rit.
        </div>
        <input
          type="phone"
          className="text-field cc-contact-field w-input"
          value={this.state.user.mobilephone}
          onChange={this.registrationFieldChanged}
          name="mobilephone"
          placeholder="06 12 34 56 78"
        />
      </div>
    );

    let consentAndRegister = (
      <div>
        <div className="w-checkbox">
          <input
            type="checkbox"
            value={this.state.user.contactPreferences.mailsAboutRides}
            onChange={this.registrationFieldChanged}
            name="mailsAboutRides"
            className="w-checkbox-input"
          />
          <label htmlFor="checkbox" className="w-htmlForm-label">
            Ik wil graag mailtjes ontvangen met ritten bij mij in de buurt.
          </label>
        </div>
        <div className="w-checkbox">
          <input
            type="checkbox"
            value={this.state.user.contactPreferences.newsletters}
            onChange={this.registrationFieldChanged}
            name="newsletters"
            className="w-checkbox-input"
          />
          <label htmlFor="checkbox-3" className="w-htmlForm-label">
            Ik wil graag sporadisch nieuwsbrieven ontvangen met de laatste
            verhalen van mederijders.
          </label>
        </div>
        <div className="w-checkbox">
          <input
            type="checkbox"
            value={this.state.user.acceptedtoc}
            onChange={this.registrationFieldChanged}
            name="acceptedtoc"
            className="w-checkbox-input"
          />
          <label htmlFor="checkbox-2" className="w-htmlForm-label">
            Ik ga akkoord met de voorwaarden zoals hier beschreven.  (*)
          </label>
        </div>
        <div className="paragraph-light ondertitel">* is verplicht</div>
      </div>
    );

    return (
      !this.props.loading ?
        !this.props.registrationCompleted ?
          <div className="section">
            <div className="intro-header cc-subpage">
              <div className="intro-content">
                <div className="heading-jumbo">
                  Registreren
              <br />
                </div>
              </div>
            </div>

            <div className="div-block">
              <h2 className="contact-heading">Leuk dat je met ons wilt rijden</h2>
              <div className="paragraph-light">
                Vul de velden hier onder in om een gratis account aan te maken. Je
            kunt daarna direct ritten bij jou in de buurt te vinden. <br />
                <br />
                Heb je al een account?
            <NavLink to="/login">Klik hier om in te loggen</NavLink>
              </div>
              <div className="contact-htmlForm w-htmlForm">
                <div
                  data-name="Get In Touch htmlForm"
                  name="wf-htmlForm-Get-In-Touch-htmlForm"
                  className="get-in-touch-htmlForm"
                >
                  <label htmlFor="name">EMAIL (*)</label>
                  <input
                    type="text"
                    className="text-field cc-contact-field w-input"
                    maxLength="140"
                    value={this.state.user.email}
                    onChange={this.registrationFieldChanged}
                    name="email"
                    placeholder="Vul je email in"
                  />
                  {!this.state.validEmail & this.state.user.email.length !== 0 ? <div className={'WarningMessage'}>Voer een gelding email adres in</div> : null}


                  <label htmlFor="Email-2">WACHTWOORD (*)</label>
                  <input
                    type="password"
                    className="text-field cc-contact-field w-input"
                    maxLength="140"
                    value={this.state.user.password}
                    onChange={this.registrationFieldChanged}
                    name="password"
                    placeholder="Vul je wachtwoord in"
                  />
                  {this.state.user.password.length <= 6 & this.state.user.password.length !== 0 ? <div className={'WarningMessage'}>Je wachtwoord moet minimaal zes karakters bevatten</div> : null}


                  {(this.state.registrationState === 1) |
                    (this.state.registrationState === 2) |
                    (this.state.registrationState === 3)
                    ? optionalPersonRegistrationFields
                    : (
                      <div
                        className="w-row"
                        ref={section => {
                          this.firstOptionalFields = section;
                        }}>
                      </div>
                    )}

                  {(this.state.registrationState === 2) |
                    (this.state.registrationState === 3)
                    ? optionalRidersDetails
                    : (
                      <div
                        className="w-row"
                        ref={section => {
                          this.secondOptionalFields = section;
                        }}>
                      </div>
                    )}

                  {(this.state.registrationState === 0) |
                    (this.state.registrationState === 1) |
                    (this.state.registrationState === 2) ?
                    this.state.user.password.length <= 6 | !this.state.validEmail ?
                      (
                        <input
                          type="submit"
                          value="VERDER"
                          disabled
                          onClick={e => this.continueRegistrationClicked(e)}
                          data-wait="Thinking..."
                          className="ButtonDisabled"
                        />
                      ) :
                      (
                        <input
                          type="submit"
                          value="VERDER"
                          onClick={e => this.continueRegistrationClicked(e)}
                          data-wait="Thinking..."
                          className="Button"
                        />
                      ) :
                    (
                      <div
                        className="w-row"
                        ref={section => {
                          this.thirdOptionalFields = section;
                        }}>
                      </div>
                    )}

                  {this.state.registrationState === 3 ? consentAndRegister : (
                    <div
                      className="w-row"
                      ref={section => {
                        this.fourthOptionalFields = section;
                      }}>
                    </div>
                  )}

                  {this.state.registrationState === 3 ?
                    this.state.user.acceptedtoc ?
                      (<button
                        onClick={() => this.registerClicked()}
                        className="Button"
                      >
                        REGISTREER
                </button>
                      ) :
                      (<button
                        disabled
                        onClick={() => this.registerClicked()}
                        className="ButtonDisabled"
                      >
                        REGISTREER
              </button>)
                    : null}
                </div>
              </div>
            </div>
          </div>
          : <RegistrationSuccess user={this.state.user} /> : <Loader />
    );
  }
}

const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    blogs: state.blogs.allBlogs,
    registrationCompleted: state.users.registrationSuccess,
    loading: state.users.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides()),
    Register: (email, password, naam, telefoonnummer, isSignup) =>
      dispatch(actions.auth(email, password, naam, telefoonnummer, isSignup))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Register, axios));
