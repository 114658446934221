import React from 'react';

import { FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const FriendRitPreview = props => {

    return (
        <div className="w-row">
            <div className="w-col w-col-2">
                <p>{props.friendsRide.ride.departure.date}</p>
            </div>
            <div className="w-col w-col-2">
                <h6 className="heading-4"> {props.friendsRide.user.firstName} {props.friendsRide.user.lastName} </h6>
            </div>
            <div className="w-col w-col-3">
                <p>{props.friendsRide.ride.name}</p>
            </div>
            <div className="w-col w-col-2">
                <p>{props.friendsRide.ride.distance} km</p>
            </div>
            <div className="w-col w-col-2">
                <p>{props.friendsRide.ride.averagePace} km/u</p>
            </div>

            {/* Todo: Refactor */}
            {/* {props.friendsRide.type === 'attendee' ? props.user.id in props.friendsRide.ride.riders === false ?
                <div className="w-clearfix w-col w-col-1"><a href="/" style={{ width: '100%' }} className="button-7 w-button">✓</a></div>
                : 'Afmelden' : props.friendsRide.ride.riders ? props.user.id in props.friendsRide.ride.riders === false ? <div className="w-clearfix w-col w-col-1"><a href="/" style={{ width: '100%' }} className="button-7 w-button">✓</a></div>
                    : 'Afmelden' : <div className="w-clearfix w-col w-col-1"><a href="/" style={{ width: '100%' }} className="button-7 w-button">✓</a></div>} */}
            <div className="w-clearfix w-col w-col-1">
                <NavLink
                    to={"/rit/" + props.friendsRide.ride.id}
                    style={{ width: '100%' }}
                    className="button-7 w-button"
                >
                    <FaUser />
                </NavLink>
            </div>
        </div >
    )
}

export default FriendRitPreview;