import * as actionTypes from "../actions/actionTypes";

const initialState = {
  allBlogs: [
    // {
    //   'title': 'Opening van het platform',
    //   'description': "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet odio urna. Maecenas efficitur, velit eget varius aliquam, metus velit ullamcorper lacus, ut suscipit nunc lectus sit amet augue. Duis nulla nisl, dapibus a nisi et, tincidunt luctus nulla. Vestibulum id elementum turpis, eu elementum nunc."
    // }
  ]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_BLOGS:
      return {
        ...state,
        currentlyLoading: state.currentlyLoading + 1
      };

    default:
      return state;
  }
};

export default reducer;
