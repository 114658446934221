import React, { Component } from "react";
import scrollToComponent from "react-scroll-to-component";
import { connect } from "react-redux";
import axios from "axios";
import Loader from './../../components/UI/Loader/Loader';

// HOC
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

// Custom components
import RitPreview from "./../../components/Rit/RitPreview/RitPreview";

class Ritten extends Component {
  state = {
    searchResults: false,
    origin: 'Knopstraat 6, Utrecht',
    radius: 10,
    date: '04-04-2019',
  };

  searchRides = event => {
    event.preventDefault();
    this.props.GetRides(
      this.state.origin,
      this.state.radius,
      this.state.date
    );

    scrollToComponent(this.Blue, {
      offset: 0,
      align: "middle",
      duration: 500,
      ease: "inCirc"
    });

    this.setState({
      searchResults: true
    })

  };

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="w-layout-grid contact-form-grid">
            <div
              id="w-node-509be705858e-c8e2810c"
              className="contact-form-wrap"
            >
              <div className="contact-form-heading-wrap">
                <h2 className="contact-heading">Ritten</h2>
                <div className="paragraph-light">
                  Fietsers door heel Nederland hebben ritten aangemaakt waar jij
                  kunt aansluiten.
                </div>
              </div>
              <div className="contact-form w-form">
                <div
                  name="wf-form-Get-In-Touch-Form"
                  className="get-in-touch-form"
                >
                  <label htmlFor="name">Stad of straat</label>
                  <input
                    type="text"
                    className="text-field cc-contact-field w-input"
                    maxLength="256"
                    name="name"
                    placeholder="Straat, stad"
                    id="Name"
                  />
                  <label htmlFor="Email-2">RADIUS in KM</label>
                  <input
                    type="number"
                    className="text-field cc-contact-field w-input"
                    maxLength="256"
                    name="Email-2"
                    placeholder="10"
                    id="Email-2"
                    required=""
                  />
                  <label htmlFor="vertrekdatum">rit datum</label>
                  <input
                    type="text"
                    className="text-field cc-contact-field w-input"
                    maxLength="256"
                    name="vertrekdatum"
                    placeholder="Zaterdag, 10 Mei 2019"
                    id="vertrekdatum"
                    required=""
                  />
                  <div className="paragraph-light right-aligned">
                    <em>Klik hier om meer voorkeuren aan te geven.</em>
                  </div>
                  <button
                    onClick={e => this.searchRides(e)}
                    className="button w-button"
                  >ZOEKEN
                  </button>

                </div>
                <div className="status-message cc-success-message w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="status-message cc-error-message w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
            <div id="w-node-509be70585a6-c8e2810c">
              <div className="details-wrap">
                <div className="label">Informatie</div>
                <div className="paragraph-light">
                  Om een zo prettig mogelijke rit voor iedereen te kunnen
                  garanderen zijn er wat basis regels opgesteld.
                </div>
              </div>
              <div className="details-wrap">
                <div className="label">Huisregels</div>
                <div className="paragraph-light">
                  - Er is 5 minuten uitloopt waarna de groep altijd vertrekt.
                  <br />
                  - De groep wacht op ieder, tenzij anders aangegeven
                  <br />- Zorg dat je vervelende fietsers aangeeft op het
                  platform, zodat we het schoon kunnen houden
                </div>
              </div>
              <div className="details-wrap">
                <div className="label">Vragen</div>
                <div className="paragraph-light">
                  Heb je nog vragen en/of opmerkingen?
                </div>
                <a
                  href="mailto:contact@business.com?subject=You&#x27;ve%20got%20mail!"
                  className="contact-email-link"
                >
                  vragen@tgtr.nl
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="search-results"
          ref={section => {
            this.Blue = section;
          }}
        >
          {this.state.searchResults ?
            Object.keys(this.props.allRides).length > 0
              ? Object.entries(this.props.allRides).map(function (ride) {
                return <RitPreview key={ride[1].id} ride={ride[1]} />;
              })
              : <Loader
                loading={this.state.loading}
              />
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allRides: state.ritten.currentRides,
    currentlyLoading: state.ritten.currentlyLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetRides: (origin, radius, date) => dispatch(actions.GetRides(origin, radius, date))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Ritten, axios));
