import React from 'react';

import Aux from './../../../hoc/Auxo/Auxo';
import Backdrop from './../../UI/Backdrop/Backdrop';
import NavigationItems from '../NavigationItems/NavigationItems';

import './SideDrawer.css';

const sideDrawer = (props) => {
    let attachedClasses = ['SideDrawer', 'Close']

    if (props.open) {
        attachedClasses = ['SideDrawer', 'Open']
    }
    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(' ')} onClick={props.closed}>
                <nav>
                    <NavigationItems
                        logout={props.logout}
                        isAuth={props.isAuth} />
                </nav>
            </div>
        </Aux>
    );
}

export default sideDrawer;