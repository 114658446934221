import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Loader from '../../../components/UI/Loader/Loader';

// HOC
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";
import './RitMaken.css';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

class RitMaken extends Component {
  state = {
    ride: {
      name: '',
      recogniseMeBy: '',
      departure: {
        time: '',
        date: '',
        location: {
          street: '',
          city: '',
          lat: '',
          lng: ''
        }
      },
      distance: 0,
      averagePace: 0,
      maxAmountOfRiders: 20,
      stravaDetails: {
        routeUrl: ''
      }
    },
    rideDetailsComplete: false,
    validatedFields: {
      'name': true,
      date: true,
      time: true,
      recogniseMeBy: true,
      adres: true
    },
    showCalendar: false,
    departureDate: '09-09-2019'
  }

  updateRideDetails = (e) => {
    let ride = { ...this.state.ride };

    if (e.target.name === 'date' | e.target.name === 'time') {
      ride.departure[e.target.name] = e.target.value;
    } else if (e.target.name === 'street' | e.target.name === 'city') {
      ride.departure.location[e.target.name] = e.target.value;
    } else if (e.target.name === 'routeUrl') {
      ride.stravaDetails[e.target.name] = e.target.value;
    } else {
      ride[e.target.name] = e.target.value;
    }

    this.setState({
      ride: ride,
      rideDetailsComplete: this.checkFields(ride)
    })
  }

  checkFields = (ride) => {
    let errorFields = [];
    let returnValue = true;
    if (ride.name.length < 5) {
      errorFields.push('Name < 5')
      returnValue = false;
    } else if (Number(ride.distance) < 10) {
      errorFields.push('Distance < 10')
      returnValue = false;
    } else if (Number(ride.maxAmountOfRiders) < 2) {
      errorFields.push('amountOfRiders < 2');
      returnValue = false;
    } else if (ride.recogniseMeBy.length < 8) {
      errorFields.push('Recognise me By < 8')
      returnValue = false;
    } else if (ride.departure.date.length !== 10) {
      errorFields.push('Date length !== 10');
      returnValue = false;
    } else if (ride.departure.time.length !== 5) {
      errorFields.push('Time length !== 5');
      returnValue = false;
    } else if (ride.departure.location.street.length < 4) {
      errorFields.push('Street length < 4');
      returnValue = false;
    } else if (ride.departure.location.city.length < 4) {
      errorFields.push('City length < 4');
      returnValue = false;
    } else if (ride.stravaDetails.routeUrl.length > 0) {
      const STRAVA_REGEX = new RegExp("https://www.strava.com/routes/([0-9])+")
      if (!STRAVA_REGEX.test(ride.stravaDetails.routeUrl)) {
        errorFields.push('Strava Route URL did not match');
        returnValue = false;
      }
    }

    if (errorFields.length > 0) {
      console.log('We found the following errors while checking the fields', errorFields)
    }
    return returnValue;
  }

  storeRide = e => {
    e.preventDefault();
    let host = { ...this.props.user }
    let ride = { ...this.state.ride }
    ride['host'] = host
    this.props.storeRide(ride);
    NotificationManager.success('We hebben je rit aangemaakt', 'Rit aangemaakt!');
  }

  getFormattedDate = date => {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return day + "-" + month + '-' + year;
  }

  handleDayClick = day => {
    let ride = { ...this.state.ride };
    ride.departure.date = this.getFormattedDate(day);

    this.setState({
      ride: ride,
      showCalendar: false,
      departureDate: this.getFormattedDate(day)
    });
  }

  render() {
    return (
      <div className="section cc-home-wrap">
        <div className="heading-jumbo">
          Rit aanmaken
          <br />
        </div>
        <div className="container">
          <div className="motto-wrap" />
          <div className="divider" />
          <div className="about-story-wrap" />
          <div className="div-block">
            <h2 className="contact-heading">Tof dat je een rit wilt aanmaken</h2>
            <div className="paragraph-light">
              Leuk dat je met andere rijders een ritje wilt maken. We hebben
              verschillende gegevens nodig voordat we je rit openbaar kunnen
              maken en andere zich kunnen aanmelden. Alle velden zijn verplicht.
              <br />‍
            </div>

            <div className="contact-form w-form">
              <div
                name="wf-form-Get-In-Touch-Form"
                className="get-in-touch-form"
              >
                <label htmlFor="name">TITEL</label>
                {!this.state.validatedFields.name ?
                  <div className={'ValidationError'}>
                    Titel moet minstens 5 letters bevatten
                </div>
                  : null}
                <input
                  type="text"
                  className="text-field cc-contact-field w-input"
                  maxLength="256"
                  name="name"
                  onBlur={(e) => e.target.value.length < 10 ? this.setState({ validatedFields: { ...this.state.validatedFields, name: false } }) : this.setState({ validatedFields: { ...this.state.validatedFields, name: true } })}
                  onChange={(e) => this.updateRideDetails(e)}
                  placeholder="Rondje loosdrechtse plassen"
                />

                <label htmlFor="name-3">Waar kunnen mensen je aan herkennen?</label>
                {!this.state.validatedFields.recogniseMeBy ?
                  <div className={'ValidationError'}>
                    Vul hier minstens 10 letters in
                </div>
                  : null}
                <input
                  type="text"
                  className="text-field cc-contact-field w-input"
                  maxLength="256"
                  name="recogniseMeBy"
                  onBlur={(e) => e.target.value.length < 10 ? this.setState({ validatedFields: { ...this.state.validatedFields, recogniseMeBy: false } }) : this.setState({ validatedFields: { ...this.state.validatedFields, recogniseMeBy: true } })}
                  onChange={(e) => this.updateRideDetails(e)}
                  placeholder="Canyon rood - Shirt van Amstel gold Race - Gele helm"
                />

                <div>
                  <div className="w-row">
                    <div className="column w-col w-col-6">
                      <label htmlFor="Email">VERTREKDATUM</label>
                      {this.state.showCalendar ?
                        <DayPicker
                          onDayClick={this.handleDayClick} />
                        : null}
                      <input
                        onSelect={() => this.setState({ showCalendar: true })}
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="date"
                        value={this.state.ride.departure.date}
                        placeholder="09-05-2019"
                      />
                    </div>
                    <div className="column-2 w-col w-col-6">
                      <label htmlFor="Email">VERTREKTIJD</label>
                      {!this.state.validatedFields.time ?
                        <div className={'ValidationError'}>
                          TIjd moet exact HH:MM formaat zijn
                </div>
                        : null}
                      <input
                        type="time"
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="time"
                        onBlur={(e) => e.target.value.length !== 5 ? this.setState({ validatedFields: { ...this.state.validatedFields, time: false } }) : this.setState({ validatedFields: { ...this.state.validatedFields, time: true } })}
                        onChange={(e) => this.updateRideDetails(e)}
                        placeholder="11:30"
                      />
                    </div>
                  </div>
                  <div className="w-row">
                    <div className="column w-col w-col-6">
                      <label htmlFor="Email-3">VERTREK ADRES</label>
                      {!this.state.validatedFields.adres ?
                        <div className={'ValidationError'}>
                          Een adres hoort te zijn opgemaakt op de volgende wijze: Straat 5
                        </div>
                        : null}
                      <input
                        type="text"
                        onBlur={(e) => new RegExp('[A-Z][a-z]+ [0-9]+', 'i').test(e.target.value) ? this.setState({ validatedFields: { ...this.state.validatedFields, adres: true } }) : this.setState({ validatedFields: { ...this.state.validatedFields, adres: false } })}
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="street"
                        onChange={(e) => this.updateRideDetails(e)}
                        placeholder="Uithoff 13"
                      />
                    </div>
                    <div className="column-2 w-col w-col-6">
                      <label htmlFor="Email-3">STAD</label>
                      <input
                        type="text"
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="city"
                        onChange={(e) => this.updateRideDetails(e)}
                        placeholder="Utrecht"
                      />
                    </div>
                  </div>
                  <div className="w-row">
                    <div className="column w-col w-col-6">
                      <label htmlFor="Email">AFSTAND</label>
                      <input
                        type="number"
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="distance"
                        onChange={(e) => this.updateRideDetails(e)}
                        placeholder="50"
                      />
                    </div>
                    <div className="column-3 w-col w-col-6">
                      <label htmlFor="Email">GEMIDDELDE SNELHEID</label>
                      <input
                        type="number"
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="averagePace"
                        onChange={(e) => this.updateRideDetails(e)}
                        placeholder="30"
                      />
                    </div>
                  </div>
                  <div className="w-row">
                    <div className="column w-col w-col-6">
                      <label htmlFor="afstand">MAXIMAAL AANTAL DEELNEMERS</label>
                      <input
                        type="number"
                        className="text-field cc-contact-field w-input"
                        maxLength="256"
                        name="maxAmountOfRiders"
                        onChange={(e) => this.updateRideDetails(e)}
                        placeholder="20"
                      />
                    </div>
                    <div className="column-4 w-col w-col-6" />
                  </div>
                  <label htmlFor="name-3">STRAVA ROUTE LINK</label>
                  <input
                    type="text"
                    className="text-field cc-contact-field w-input"
                    maxLength="256"
                    name="routeUrl"
                    value={this.state.ride.stravaDetails.routeUrl}
                    onChange={(e) => this.updateRideDetails(e)}
                    placeholder="https://www.strava.com/route/rondje-loosdrechtse-plassen"
                  />
                </div>
                <button
                  type="submit"
                  disabled={!this.state.rideDetailsComplete}
                  onClick={(e) => this.storeRide(e)}
                  className={this.state.rideDetailsComplete ? "button w-button" : 'ButtonDisabled'}
                >AANMAKEN</button>
              </div>
              <Loader
                loading={this.props.loading} />
              <NotificationContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    blogs: state.blogs.allBlogs,
    loading: state.ritten.loading,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides()),
    storeRide: (ride) => dispatch(actions.StoreRide(ride))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(RitMaken, axios));
