import React from "react";
import CookieConsent from "react-cookie-consent";
import { NavLink } from "react-router-dom";

const footer = props => {
  return (
    <div>
      <div className="section cc-cta">
        <div className="container">
          <div className="cta-wrap">
            <div>
              <div className="cta-text">
                <div className="heading-jumbo-small">
                  Vind een rit
                  <br />
                </div>

                <div className="paragraph-bigger cc-bigger-light">
                  {props.authenticated ? (
                    'Ga direct op zoek naar jouw volgende rit'
                  ) : (
                      'Begin vandaag met het vinden van ritten via TGTR. Maak je nieuwe account en begin direct met het fietsen in groepen volgens jouw agenda')}
                  <br />
                </div>
              </div>
              {props.authenticated ? (
                <NavLink
                  to="/ritten"
                  className="button cc-jumbo-button w-inline-block"
                >
                  <div>ZOEK RITTEN</div>
                </NavLink>
              ) : <NavLink
                to="/login"
                className="button cc-jumbo-button w-inline-block"
              >
                  <div>INLOGGEN</div>
                </NavLink>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="footer-wrap">
            <a
              href="https://www.tinqr.nl/"
              target="_blank"
              rel="noopener noreferrer"
              className="webflow-link w-inline-block"
            >
              <div className="paragraph-tiny">Powered by TINQR</div>
            </a>
          </div>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accepteren"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={50000}
      >
        Wij gebruiken cookies om jou een betere ervaring te kunnen bieden.{" "}
        <span style={{ fontSize: "10px" }}>
          Voor meer informatie over hoe wij jouw gegevens gebruiken kun je
          kijken op de 'Privacy en Cookies pagina'
        </span>
      </CookieConsent>
    </div>
  );
};

export default footer;
