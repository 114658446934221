import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';

import UserPreview from '../../../components/User/UserPreview/UserPreview'
import RitMessageOverview from '../../../components/Rit/RitMessages/RitMessageOverview/RitMessagesOverview';
import SendRitMessage from './../../../components/Rit/RitMessages/SendRitMessage/SendRitMessage';

// HOC
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";

// UI avatars
import Loader from './../../../components/UI/Loader/Loader';

class RitDetails extends Component {
    state = {
        loading: true,
        showMessages: false,
        messagesAvailable: false,
        amountOfMessages: 5,
        checked: false,
        message: '',
        messageSent: false
    }

    componentDidUpdate() {
        if (this.props.selectedRide !== null & this.state.checked === false) {
            if (this.props.selectedRide.messages !== undefined) {
                this.setState({
                    checked: true,
                    messagesAvailable: true
                })
            } else {
                this.setState({
                    checked: true
                })
            }
        }
    }

    componentDidMount() {
        const { rideId } = this.props.match.params;
        this.props.GetRideDetails(rideId)
    }

    leaveRide = (e) => {
        e.preventDefault();
        this.props.leaveRide(
            this.props.selectedRide,
            this.props.user
        );
        NotificationManager.success('Je staat niet meer op aanwezig voor deze rit', 'Jammer maar helaas :(');
    }

    joinRide = (e) => {
        e.preventDefault();
        this.props.JoinRide(
            this.props.selectedRide,
            this.props.user
        );
        NotificationManager.success('Je hebt jezelf succesvol aangemeld voor deze rit', 'Je doet mee!');
    }

    saveMessage = (e) => {
        e.preventDefault();
        this.props.addMessageToRide(
            this.props.selectedRide,
            this.props.user,
            this.state.message
        )
        this.setState({
            messageSent: true
        })
        NotificationManager.success('Nu is het alleen nog wachten op een reactie..', 'Bericht in de lucht');
    }

    render() {
        let ridersPreviews = [];

        if (this.props.selectedRide !== null) {
            if (this.props.selectedRide.riders !== undefined) {
                if (Object.keys(this.props.selectedRide.riders).length > 0) {
                    Object.keys(this.props.selectedRide.riders).forEach((riderId, index) => {
                        ridersPreviews.push(<UserPreview key={index} user={this.props.selectedRide.riders[riderId]} />)
                    });
                }
            }
        }

        return (
            this.props.selectedRide ? (
                <div className="section cc-home-wrap">
                    <div className="container">
                        <div className="motto-wrap">
                            <div className="heading-jumbo-small">{this.props.selectedRide.name}</div>
                        </div>
                        <div className="divider"></div>
                        <div className="w-row">
                            <div className="column-5 w-col w-col-3">
                                <h6>Feiten</h6>
                                <ul className="list-2">
                                    <li>
                                        <p className="paragraph-light">{this.props.selectedRide.departure.date}<br /></p>
                                    </li>
                                    <li>
                                        <p className="paragraph-light">{this.props.selectedRide.departure.time} - 12:10 (*)</p>
                                    </li>
                                    <li>
                                        <p className="paragraph-light">{this.props.selectedRide.departure.location.street} {this.props.selectedRide.departure.location.streetNumber}, {this.props.selectedRide.departure.location.city}<br /></p>
                                    </li>
                                </ul>
                                <h6>Rit gegevens</h6>
                                <ul className="list-2">
                                    <li>
                                        <p className="paragraph-light">{this.props.selectedRide.distance} kilometer<br /></p>
                                    </li>
                                    <li>
                                        <p className="paragraph-light">{this.props.selectedRide.averagePace} kilometer per uur (*)<br /></p>
                                    </li>
                                </ul>
                                <p className="paragraph-light ondertitel"><em>* geschat door organisator</em><br /></p>
                                <h6>Berichten</h6>
                                {!this.state.showMessages ? <p className="paragraph-light ondertitel" onClick={() => this.setState({ showMessages: true })}><em>Klik hier om berichten te lezen</em><br /></p> : null}
                                {this.state.showMessages ?
                                    <div>
                                        {this.state.messagesAvailable ?
                                            (
                                                <div>
                                                    <RitMessageOverview
                                                        amountOfMessages={this.state.amountOfMessages}
                                                        messages={this.state.messagesAvailable ? this.props.selectedRide.messages : null}
                                                    />
                                                    {Object.keys(this.props.selectedRide.messages).length > this.state.amountOfMessages ? <p className="paragraph-light ondertitel" onClick={() => this.setState({ amountOfMessages: this.state.amountOfMessages + 5 })}><em>Laad meer berichten...</em><br /></p> : null}
                                                </div>
                                            ) : <p className="paragraph-light ondertitel"><em>Nog geen berichten geplaatst</em></p>}
                                        <SendRitMessage
                                            message={this.state.message}
                                            messageChanged={(e) => {
                                                this.setState({ message: e.target.value })
                                            }}
                                            send={(e) => this.saveMessage(e)}
                                            messageSent={this.state.messageSent}
                                        />
                                    </div>
                                    : null}
                            </div>
                            <div className="w-col w-col-9">
                                <ul className="list">
                                    <li>
                                        <h4>Organisator</h4>
                                        <UserPreview user={this.props.selectedRide.host} />
                                    </li>
                                    <li>
                                        <h4 className="heading">Rijders</h4>
                                        {ridersPreviews.length > 0 ?
                                            ridersPreviews.map(riderPreview => {
                                                return riderPreview
                                            })
                                            : 'Er zijn nog geen rijders aangemeld'}
                                    </li>
                                    <li>
                                        <NotificationContainer />
                                        <a href="/login" className="button-3 w-button">Strava route</a>
                                        {this.props.selectedRide.riders !== undefined ?
                                            this.props.user.id in this.props.selectedRide.riders ?
                                                (
                                                    <button
                                                        onClick={(e) => this.leaveRide(e)}
                                                        className="button-2 w-button">
                                                        Afmelden
                                            </button>
                                                ) :
                                                <button
                                                    onClick={(e) => this.joinRide(e)}
                                                    className="button-2 w-button">
                                                    Aanmelden
                                                </button>
                                            :
                                            this.props.user !== null ?
                                                <button
                                                    onClick={(e) => this.joinRide(e)}
                                                    className="button-2 w-button">
                                                    Aanmelden
                                                </button>
                                                : 'Log in om je bij deze rit aan te melden'}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <Loader loading={true} />)
    }
}

const mapStateToProps = state => {
    return {
        user: state.users.user,
        selectedRide: state.ritten.selectedRide
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GetRideDetails: (rideId) => dispatch(actions.GetRideDetails(rideId)),
        JoinRide: (ride, user) => dispatch(actions.joinRide(ride, user)),
        addMessageToRide: (ride, user, message) => dispatch(actions.addMessageToRide(ride, user, message)),
        leaveRide: (ride, user) => dispatch(actions.leaveRide(ride, user))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(RitDetails, axios));
