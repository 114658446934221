import React from 'react';
import { NavLink } from "react-router-dom";


const navigationItems = (props) => (
    <div>

        <NavLink className="navigation-item w-nav-link" to="/">
            Home
                </NavLink> <br />

        <NavLink className="navigation-item w-nav-link" to="/over-ons">
            OVER
                </NavLink><br />


        {props.isAuth ?
            <div>
                <NavLink
                    className="navigation-item w-nav-link"
                    to="/ritten"
                >
                    RITTEN VINDEN
                    </NavLink><br />
                <NavLink
                    className="navigation-item w-nav-link"
                    to="/ritten-van-vrienden"
                >
                    RITTEN VAN VRIENDEN
                    </NavLink><br />
                <NavLink
                    className="navigation-item w-nav-link"
                    to="/rit-maken"
                >
                    RIT MAKEN
                    </NavLink><br />


                <NavLink
                    className="navigation-item w-nav-link"
                    to="/mijn-profiel"
                >
                    MIJN PROFIEL
                    </NavLink><br />
                <NavLink
                    className="navigation-item w-nav-link"
                    to="/vrienden-vinden"
                >
                    MIJN VRIENDEN
                    </NavLink><br />
                <NavLink
                    className="navigation-item w-nav-link"
                    to="/mijn-ritten"
                >
                    MIJN RITTEN
                    </NavLink><br />
            </div>
            : null}

        <NavLink className="navigation-item w-nav-link" to="/blog">
            BLOG
                </NavLink><br />
        <NavLink className="navigation-item w-nav-link" to="/contact">
            CONTACT
                </NavLink><br />

        {props.isAuth === false ?
            (
                <NavLink
                    to="/login"
                    href="contact.html"
                    className="navigation-item w-nav-link"
                >
                    <div>INLOGGEN</div>
                </NavLink>
            ) : (
                <div
                    onClick={props.logout}
                    className="navigation-item w-nav-link"
                >
                    <div>UITLOGGEN</div>
                </div>
            )}
    </div >
);

export default navigationItems;