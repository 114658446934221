// RITTEN
export const GET_ALL_RIDES = "GET_ALL_RIDES";
export const GET_RIDES = "GET_RIDES";
export const GET_RIDE_DETAILS = "GET_RIDE_DETAILS";
export const STORE_RIDE = "STORE_RIDE";
export const STORE_RIDE_SUCCESS = "STORE_RIDE_SUCCESS";
export const INIT_STORE_RIDE = "INIT_STORE_RIDE;";
export const ADD_MESSAGE_TO_RIDE = 'ADD_MESSAGE_TO_RIDE';
export const ADD_MESSAGE_TO_RIDE_SUCCESS = 'ADD_MESSAGE_TO_RIDE_SUCCESS';

// USERS
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const INIT_GET_USER = "INIT_GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const JOIN_RIDE = "JOIN_RIDE";
export const INIT_JOIN_RIDE = "INIT_JOIN_RIDE";
export const JOIN_RIDE_SUCCESS = "JOIN_RIDE_SUCCESS";
export const INIT_UPDATE_RIDE = "INIT_UPDATE_RIDE";
export const UPDATE_RIDE_SUCCESS = "UPDATE_RIDE_SUCCESS";
export const INIT_GET_FRIENDS_DETAILS = "INIT_GET_FRIENDS_DETAILS";
export const GET_FRIENDS_DETAILS_SUCCESS = "GET_FRIENDS_DETAILS_SUCCESS";
export const INIT_INVITE_FRIEND = 'INIT_INVITE_FRIEND';
export const INVITE_FRIEND = 'INVITE_FRIEND';
export const INVITE_FRIEND_SUCCESS = 'INVITE_FRIEND_SUCCESS';
export const SEARCH_USER_BY_EMAIL = 'SEARCH_USER_BY_EMAIL';
export const INIT_SEARCH_USER_BY_EMAIL = 'INIT_SEARCH_USER_BY_EMAIL';
export const SEARCH_USER_BY_EMAIL_SUCCESS = 'SEARCH_USER_BY_EMAIL_SUCCESS';
export const INIT_ACCEPT_FRIEND_REQUEST = 'INIT_ACCEPT_FRIEND_REQUEST';
export const ACCEPT_FRIEND_REQUEST_SUCCESS = 'ACCEPT_FRIEND_REQUEST_SUCCESS';

export const AUTHENTICATE = "AUTHENTICATE";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UPDATE_USER";
export const USER_UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const USER_UPDATE_INIT = "USER_UPDATE_INIT";

// Auth/Boot specific
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_ENTRY_POINT = "SET_ENTRY_POINT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";

// BLOGS
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
