import React from 'react';
import { ClipLoader } from 'react-spinners';

const override = {
    'display': 'block',
    'margin': '0 auto',
    position: "fixed",
    'borderColor': 'red',
    transform: "translate(-50%, -50%)",
    top: "30%",
    left: "45%",
}

const Loader = props => {
    let loader = (
            <div style={{ textAlign: 'center', minHeight: 400 }}>
                We zijn nog bezig met jouw gegevens ophalen...
    
                <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={true}
                />
            </div>
    )

    if (props.loading !== undefined) {
        if (props.loading) {
            loader = (
            <div style={{ textAlign: 'center', minHeight: 400 }}>
                We zijn nog bezig met jouw gegevens ophalen...

                    <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={props.loading}
                />
            </div>
            )
        } else {
            loader = null;
        }
    }
        
    return loader
}

export default Loader;