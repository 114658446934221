import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ClipLoader } from 'react-spinners';

// HOC
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";

import LogoutSuccess from './../../../components/User/LogoutSuccess/LogoutSuccess';

const override = {
    'display': 'block',
    'margin': '0 auto',
    position: "fixed",
    'borderColor': 'red',
    transform: "translate(-50%, -50%)",
    top: "30%",
    left: "45%"
}

class Logout extends Component {
    render() {
        return (
            this.props.loading & this.props.isAuthenticated ?
                (
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={'#123abc'}
                        loading={this.props.loading}
                    />
                )
                : <LogoutSuccess />
        )
    }
}

const mapStateToProps = state => {
    return {
        currentlyLoading: state.ritten.currentlyLoading,
        blogs: state.blogs.allBlogs,
        loading: state.users.loading,
        error: state.users.error,
        isAuthenticated: state.users.token !== null,
        authRedirectPath: state.users.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GetAllRides: () => dispatch(actions.GetAllRides()),
        onAuth: (email, password, naam, telefoonnummer, isSignup) => dispatch(actions.auth(email, password, naam, telefoonnummer, isSignup)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(Logout, axios));

