import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';

// HOC
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";
import UserPreview from "../../components/User/UserPreview/UserPreview";

class MyFriends extends Component {
  state = {
    searchingForFriends: false,
    searchForEmail: ""
  };

  componentDidMount() {
    this.props.GetFriendsDetailsForUser(this.props.user.id);
  }

  searchForFriends = e => {
    e.preventDefault();
    this.props.searchUsersByEmail(this.state.searchForEmail);
    this.setState({ searchingForFriends: true });
  };

  sendFriendRequest = (e, friend) => {
    e.preventDefault();
    this.props.inviteFriend(this.props.user, friend);
    NotificationManager.success('Je hebt ' + friend.email + ' een uitnodiging verstuurd', 'Verstuurd!');
    this.props.GetFriendsDetailsForUser(this.props.user.id);
  };

  render() {
    let friendsPreviews = [];
    let openFriendRequests = [];

    if (this.props.user) {
      if (this.props.user.friends) {
        Object.keys(this.props.user.friends).forEach((userId, index) => {
          friendsPreviews.push(
            <UserPreview key={index} user={this.props.user.friends[userId]} />
          );
        });
      }

      if (this.props.user) {
        if (this.props.user.openFriendRequests) {
          if (Object.keys(this.props.user.openFriendRequests).length > 0) {
            Object.keys(this.props.user.openFriendRequests).forEach(requestId => {
              let friendsRequest = {
                ...this.props.user.openFriendRequests[requestId]
              };
              return openFriendRequests.push(
                <li key={requestId}>
                  <div className="columns w-row">
                    <div className="w-col w-col-6">
                      <div className="text-block-3">{friendsRequest.email}</div>
                    </div>
                    <div className="w-clearfix w-col w-col-3">
                      <button className="button-7 w-button">X</button>
                    </div>
                    <div className="w-clearfix w-col w-col-3">
                      <a onClick={(e) => {
                        e.preventDefault();
                        this.props.acceptFriendRequest(this.props.user, friendsRequest);
                        NotificationManager.success('Je hebt ' + friendsRequest.email + ' geaccepteerd als vriend!', 'Vrienden!');
                        this.props.GetFriendsDetailsForUser(this.props.user.id);
                      }} href="/" className="button-7 w-button">
                        ✓
                  </a>
                    </div>
                  </div>
                </li>
              );
            });
          }
        }
      }
    }

    return (
      <div className="container">
        <NotificationContainer />

        <div className="motto-wrap">
          <div className="heading-jumbo-small">Jouw vrienden</div>
        </div>
        <div className="divider" />
        <div className="w-row">
          <div className="w-col w-col-3">
            {openFriendRequests !== undefined ? (
              openFriendRequests.length > 0 ? (
                <div>
                  <h4 className="heading-2">Openstaande verzoeken</h4>
                  <ul className="w-list-unstyled">
                    {openFriendRequests.map(friendRequest => {
                      return friendRequest;
                    })}
                  </ul>
                </div>
              ) : null
            ) : null}
            <h4 className="heading-2">Toevoegen</h4>
            <div className="w-form">
              <div id="email-form" name="email-form">
                <input
                  type="email"
                  className="w-input"
                  maxLength="256"
                  name="email"
                  value={this.state.searchForEmail}
                  onChange={e =>
                    this.setState({ searchForEmail: e.target.value })
                  }
                  placeholder="Zoek op e-mail"
                  id="email"
                  required=""
                />
                <button
                  type="submit"
                  value="ZOEKEN"
                  onClick={e => this.searchForFriends(e)}
                  className="submit-button w-button"
                >
                  ZOEKEN
                </button>
              </div>
            </div>

            {this.state.searchingForFriends &
              (this.props.userSearchResults !== null & this.props.userSearchResults !== undefined) ? (
                this.props.userSearchResults.length > 0 ? (
                  <div>
                    <h6>Gevonden</h6>
                    <ul className="w-list-unstyled">
                      {this.props.userSearchResults.map(searchResult => {
                        return (
                          <li key={searchResult.email}>
                            <div className="columns w-row">
                              <div className="w-col w-col-6">
                                <div className="text-block-3">
                                  {searchResult.email}
                                </div>
                              </div>
                              <div className="w-clearfix w-col w-col-3">
                                <NavLink
                                  to={"/profiel/" + searchResult.id}
                                  className="button-7 w-button"
                                >
                                  <FaUser />
                                </NavLink>
                              </div>
                              <div className="w-clearfix w-col w-col-3">
                                <button
                                  className="button-7 w-button"
                                  onClick={e =>
                                    this.sendFriendRequest(e, searchResult)
                                  }
                                >
                                  ✓
                              </button>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                    "We konden geen gebruikers vinden met dit e-mail adres"
                  )
              ) : null}
          </div>
          <div className="w-col w-col-9">
            <ul className="list">
              <li>
                {friendsPreviews.length > 0
                  ? friendsPreviews.map(userPreview => {
                    return userPreview;
                  })
                  : "Je hebt nog geen vrienden toegevoegd"}
              </li>
            </ul>
          </div>
        </div>
        <div className="divider" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    user: state.users.user,
    blogs: state.blogs.allBlogs,
    userSearchResults: state.users.userSearchResults
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides()),
    searchUsersByEmail: email => dispatch(actions.searchUsersByEmail(email)),
    inviteFriend: (user, friend) =>
      dispatch(actions.inviteFriend(user, friend)),
    acceptFriendRequest: (user, friendRequest) => dispatch(actions.acceptFriendRequest(user, friendRequest)),
    GetFriendsDetailsForUser: userId =>
      dispatch(actions.getFriendsDetailsForUser(userId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(MyFriends, axios));
