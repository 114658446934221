import React from 'react';
import { FaBars } from 'react-icons/fa';
import classes from './DrawerToggle.css';

const menu = (props) => {
    return (
        <div className={classes.DrawerToggle} onClick={props.clicked}>
            <div><FaBars size={'2em'} /></div>
        </div>
    );
}

export default menu;