import React from 'react';

const RitMessagesOverview = props => {
    let messages = [];
    let i = 0;
    if (props.messages) {
        Object.keys(props.messages).forEach(messageKey => {
            const message = props.messages[messageKey];
            if (message !== null & i < props.amountOfMessages) {
                let actualMessage = message[Object.keys(message)[0]];
                let author = Object.keys(message)[0];
                messages.push(
                    [
                        author,
                        actualMessage
                    ]
                )
                i++;
            }
        })
    }

    return (
        <div>
            <ul className="w-list-unstyled">
                {messages.map( (message, index) => {
                    return (
                        <li key={index}>
                            <div className="text-block-4"><strong>{message[0]}</strong><br />{message[1]}</div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default RitMessagesOverview;