import React from 'react';

const SendRitMessage = props => {
    return (
        <div className="w-form">
            <div id="email-form" name="email-form" className="form">
                <input
                    type="text"
                    className="w-input"
                    maxLength="256"
                    name="message"
                    onChange={props.messageChanged}
                    value={props.message}
                    placeholder="Jouw bericht"
                    id="message"
                    disabled={props.messageSent}
                />
                <button
                    style={{ width: '100%' }}
                    type="submit"
                    value="VERSTUREN"
                    onClick={props.send}
                    className="submit-button-2 w-button"
                    disabled={props.messageSent}>
                    VERSTUREN
                </button>
            </div>
        </div>
    )
}

export default SendRitMessage;