import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

// HOC
import Aux from "./../../hoc/Auxo/Auxo";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

class Blogs extends Component {
  render() {
    return (
      <Aux>
        <div className="section cc-home-wrap">
          <div className="intro-header cc-subpage">
            <div className="intro-content">
              <div className="heading-jumbo">
                Blog
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="section-heading-wrap">
              <div className="label cc-light">DIT ZIJN DE LAATSTE VERHALEN</div>
              <h2>Onze verhalen</h2>
            </div>
            <div className="w-dyn-list">
              {this.props.blogs.length > 0 ? (
                <div className="blog-list-wrap w-dyn-items">
                  <div className="blog-item w-dyn-item">
                    <a href="/over-ons" className="blog-preview-image w-inline-block">Preview</a>
                    <div className="blog-summary-wrap">
                      <a href="/over-ons" className="blog-heading-link">Link</a>
                      <div className="paragraph-light" />
                      <div className="label cc-blog-date" />
                    </div>
                  </div>
                </div>
              ) : (
                  <div className="status-message cc-no-data w-dyn-empty">
                    <div>Geen blogs gevonden.</div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    blogs: state.blogs.allBlogs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Blogs, axios));
