import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getAllRidesSuccess = data => {
  return {
    type: actionTypes.GET_RIDES,
    rides: data
  };
};

export const GetAllRides = () => {
  return dispatch => {
    axios
      .get("https://tgtr-6b72e.firebaseio.com/allRides.json")
      .then(allRides => {
        dispatch(getAllRidesSuccess(allRides.data));
      });
  };
};

export const getRidesSuccess = data => {
  return {
    type: actionTypes.GET_RIDES,
    rides: data
  };
};

export const GetRides = (origin, radius, date) => {
  // Get all rides and filter out according to parameters
  return dispatch => {
    axios
      .get("https://tgtr-6b72e.firebaseio.com/allRides.json")
      .then(allRides => {
        dispatch(getRidesSuccess(allRides.data));
      });
  };
};

const getRideDetailsSuccess = ride => {
  return {
    type: actionTypes.GET_RIDE_DETAILS,
    ride: ride
  };
};

export const GetRideDetails = rideId => {
  return dispatch => {
    axios
      .get("https://tgtr-6b72e.firebaseio.com/allRides/" + rideId + ".json")
      .then(selectedRide => {
        const ride = selectedRide.data;
        dispatch(getRideDetailsSuccess(ride));
      });
  };
};

const storeRideSuccess = data => {
  return {
    type: actionTypes.STORE_RIDE_SUCCESS
  };
};

const initStoreRide = ride => {
  return {
    type: actionTypes.INIT_STORE_RIDE
  };
};

export const StoreRide = ride => {
  const rideIdentifier =
    ride.departure.location.city.replace(/\s+/g, "-").toLowerCase() +
    "-" +
    ride.name.replace(/\s+/g, "-").toLowerCase();
  ride["id"] = rideIdentifier;
  return dispatch => {
    dispatch(initStoreRide());
    ride.riders = {}
    axios
      .put(
        "https://tgtr-6b72e.firebaseio.com/allRides/" +
        rideIdentifier +
        ".json",
        ride
      )
      .then(response => {
        dispatch(storeRideSuccess(response));
      });
  };
};

export const initJoinRide = () => {
  return {
    type: actionTypes.INIT_JOIN_RIDE
  };
};

export const joinRideSuccess = ride => {
  return {
    type: actionTypes.JOIN_RIDE_SUCCESS,
    ride: ride
  };
};

export const joinRide = (ride, user) => {
  if (ride.riders !== undefined) {
    ride.riders[user.id] = user;
  } else {
    ride.riders = { [user.id]: user }
  }
  
  return dispatch => {
    dispatch(initJoinRide());
    axios
      .put(
        "https://tgtr-6b72e.firebaseio.com/allRides/" + ride.id + ".json",
        ride
      )
      .then(response => {
        dispatch(joinRideSuccess(response.data));
      });
  };
};

const initUpdateRide = () => {
  return {
    type: actionTypes.INIT_UPDATE_RIDE
  };
};

const updateRideSuccess = ride => {
  return {
    type: actionTypes.UPDATE_RIDE_SUCCESS,
    ride: ride
  };
};

export const updateRide = ride => {
  return dispatch => {
    dispatch(initUpdateRide());
    axios
      .put(
        "https://tgtr-6b72e.firebaseio.com/allRides/" + ride.id + ".json",
        ride
      )
      .then(response => {
        dispatch(updateRideSuccess(response.data));
      });
  };
};

export const leaveRide = (ride, user) => {
  delete ride.riders[user.id];

  return dispatch => {
    dispatch(initJoinRide());
    axios
      .put(
        "https://tgtr-6b72e.firebaseio.com/allRides/" + ride.id + ".json",
        ride
      )
      .then(response => {
        dispatch(joinRideSuccess(response.data));
      });
  };
};

const addMessageToRideSuccess = ride => {
  return {
    type: actionTypes.ADD_MESSAGE_TO_RIDE_SUCCESS,
    ride: ride
  };
};

export const addMessageToRide = (ride, user, message) => {
  let functional_ride = { ...ride }
  if ('messages' in functional_ride) {
    let messages = [...functional_ride['messages']]
    messages.push({ [user['firstName']]: message })
    functional_ride['messages'] = messages
  } else {
    functional_ride['messages'] = [{ [user['firstName']]: message }]
  }

  return dispatch => {
    axios
      .put(
        "https://tgtr-6b72e.firebaseio.com/allRides/" + ride.id + ".json",
        functional_ride
      )
      .then(response => {
        dispatch(addMessageToRideSuccess(response.data));
      });
  };
};
