import * as actionTypes from "../actions/actionTypes";

const initialState = {
  currentRides: [],
  selectedRide: null,
  currentlyLoading: 0,
  ritDetails: {
    show: false,
    id: 0
  },
  loading: false
};

const getAllRides = (state, action) => {
  return {
    ...state,
    currentRides: action.rides
  };
};

const getRideDetails = (state, action) => {
  return {
    ...state,
    selectedRide: action.ride
  };
};

const storeRide = (state, action) => {
  return {
    ...state,
    storeRideStatus: true
  };
};

const initStoreRide = (state, action) => {
  return {
    ...state,
    loading: true
  };
};

const storeRidesuccess = (state, action) => {
  return {
    ...state,
    loading: false
  };
};

const initJoinRide = (state, action) => {
  return {
    ...state,
    loading: true
  };
};

const joinRideSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    selectedRide: action.ride
  };
};

const initUpdateRide = (state, action) => {
  return {
    ...state,
    loading: true
  };
};

const updateRideSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    selectedRide: action.ride
  };
};

const initAddMessageToRide = (state, action) => {
  return {
    ...state,
    loading: true
  };
};

const addMessageToRideSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    selectedRide: action.ride
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_RIDES:
      return getAllRides(state, action);
    case actionTypes.GET_RIDES:
      return getAllRides(state, action);
    case actionTypes.GET_RIDE_DETAILS:
      return getRideDetails(state, action);
    case actionTypes.STORE_RIDE:
      return storeRide(state, action);
    case actionTypes.INIT_STORE_RIDE:
      return initStoreRide(state, action);
    case actionTypes.STORE_RIDE_SUCCESS:
      return storeRidesuccess(state, action);
    case actionTypes.INIT_JOIN_RIDE:
      return initJoinRide(state, action);
    case actionTypes.JOIN_RIDE_SUCCESS:
      return joinRideSuccess(state, action);
    case actionTypes.INIT_UPDATE_RIDE:
      return initUpdateRide(state, action);
    case actionTypes.UPDATE_RIDE_SUCCESS:
      return updateRideSuccess(state, action);
    case actionTypes.ADD_MESSAGE_TO_RIDE:
      return initAddMessageToRide(state, action);
    case actionTypes.ADD_MESSAGE_TO_RIDE_SUCCESS:
      return addMessageToRideSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
