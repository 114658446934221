// Third party components
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import axios from "axios";

// Custom components
import Aux from "../Auxo/Auxo";
import Footer from "./../../components/UI/Footer/Footer";
import DrawerToggle from './../../components/Navigation/SideDrawer/DrawerToggle/DrawerToggle';
import SideDrawer from './../../components/Navigation/SideDrawer/SideDrawer';

// HOC
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

// CSS components
import "./Layout.css";
import './../../assets/css/normalize.css';
import './../../assets/css/webflow.css';
import './../../assets/css/tgtr.webflow.css';

class layout extends Component {
  state = {
    showSideDrawer: false,
  }

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
    this.props.history.push('/logout');
  }

  sideDrawerOpenHandler = (e) => {
    e.preventDefault();
    this.setState({
      showSideDrawer: true
    })
  }

  sideDrawerClosedHandler = (e) => {
    e.preventDefault();
    this.setState({
      showSideDrawer: false
    })
  }


  render() {
    let conditionalDropdownMenuStyle = null;
    if (!this.props.authenticated) {
      conditionalDropdownMenuStyle = { display: 'none' }
    }

    return (
      <Aux>

        <div
          data-collapse="small"
          className="navigation w-nav"
        >
          <div className="navigation-wrap">

            {/* TGTR LGO */}
            <NavLink
              to="/"
              className="logo-link w-nav-brand w--current">
              <div className="text-block">TGTR</div>
            </NavLink>

            <div className='DesktopOnly'>
              {/* MENU ITEMS */}
              <div className="menu">
                <nav role="navigation" className="navigation-items w-nav-menu">
                  <NavLink className="navigation-item w-nav-link" to="/">
                    Home
                </NavLink>

                  <NavLink className="navigation-item w-nav-link" to="/over-ons">
                    OVER
                </NavLink>

                  <div data-hover="1" data-delay="0" className="w-dropdown" style={conditionalDropdownMenuStyle}>
                    <div className="w-dropdown-toggle">
                      <div className="w-icon-dropdown-toggle" />
                      <NavLink
                        className="navigation-item w-nav-link"
                        to="/ritten"
                      >
                        <div className="text-block-2">RITTEN</div>
                      </NavLink>
                    </div>
                    <nav className="w-dropdown-list">
                      <NavLink
                        className="navigation-item w-nav-link"
                        to="/ritten"
                      >
                        VINDEN
                    </NavLink>
                      <NavLink
                        className="navigation-item w-nav-link"
                        to="/ritten-van-vrienden"
                      >
                        RITTEN VAN VRIENDEN
                    </NavLink>
                      <NavLink
                        className="navigation-item w-nav-link"
                        to="/rit-maken"
                      >
                        MAKEN
                    </NavLink>
                    </nav>
                  </div>

                  <div data-hover="1" data-delay="0" className="w-dropdown" style={conditionalDropdownMenuStyle}>
                    <div className="w-dropdown-toggle">
                      <div className="w-icon-dropdown-toggle" />
                      <NavLink
                        className="navigation-item w-nav-link"
                        to="/mijn-profiel"
                      >
                        <div className="text-block-2">RIJDER</div>
                      </NavLink>
                    </div>
                    <nav className="w-dropdown-list">
                      <Aux>
                        <NavLink
                          className="navigation-item w-nav-link"
                          to="/mijn-profiel"
                        >
                          PROFIEL
                    </NavLink>
                        <NavLink
                          className="navigation-item w-nav-link"
                          to="/vrienden-vinden"
                        >
                          MIJN VRIENDEN
                    </NavLink>
                        <NavLink
                          className="navigation-item w-nav-link"
                          to="/mijn-ritten"
                        >
                          MIJN RITTEN
                    </NavLink>
                      </Aux>
                    </nav>
                  </div>

                  <NavLink className="navigation-item w-nav-link" to="/blog">
                    BLOG
                </NavLink>
                  <NavLink className="navigation-item w-nav-link" to="/contact">
                    CONTACT
                </NavLink>

                  {/* Mobile Login */}
                  {this.props.authenticated === false ? (
                    <NavLink
                      to="/login"
                      href="contact.html"
                      className="navigation-item login w-nav-link"
                    >
                      <div>INLOGGEN</div>
                    </NavLink>
                  ) : <div
                    onClick={(e) => this.logout(e)}
                    className="navigation-item login w-nav-link"
                  >
                      <div>LOGOUT</div>
                    </div>}

                </nav>

                <div className="menu-button w-nav-button">
                  <img
                    src="images/menu-icon_1menu-icon.png"
                    width="22"
                    alt=""
                    className="menu-icon"
                  />
                </div>
              </div>

              {/* Desktop Login */}
              <div>
                {this.props.authenticated === false ? (
                  <NavLink
                    to="/login"
                    href="contact.html"
                    className="button cc-contact-us w-inline-block"
                  >
                    <div>INLOGGEN</div>
                  </NavLink>
                ) : <div
                  onClick={(e) => this.logout(e)}
                  className="button cc-contact-us w-inline-block"
                >
                    <div>LOGOUT</div>
                  </div>}
              </div>

            </div>
          </div>
        </div>

        <SideDrawer
          isAuth={this.props.authenticated}
          user={this.props.user}
          open={this.state.showSideDrawer}
          logout={(e => this.logout(e))}
          closed={(e) => this.sideDrawerClosedHandler(e)} />

        {/* Mobile ToggleIcon */}
        <div className="MobileOnly">
          <DrawerToggle clicked={(e) => this.sideDrawerOpenHandler(e)} />
        </div>

        <main className='Content'>{this.props.children}</main>

        <Footer
          authenticated={this.props.authenticated} />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.users.token !== null,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout())
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(layout, axios)));


