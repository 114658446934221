import React from "react";
import { NavLink } from "react-router-dom";

const JoinedRitPreview = props => {
  return props.ride ? (
    <li>
      <div className="w-row">
        <div>
          <div className="w-col w-col-2">
            <div>{props.ride.departure.date}</div>
          </div>
          <div className="w-col w-col-2">
            <div>{props.ride.distance}</div>
          </div>
          <div className="w-col w-col-4">
            <div>{props.ride.name}</div>
          </div>
          <div className="w-col w-col-2">
            <NavLink to={"/rit/" + props.ride.id} className="button-4 w-button">
              Details
            </NavLink>
          </div>
          {props.userId ? (
            props.ride.host.id === props.userId ? (
              <div className="w-col w-col-2">
                <NavLink
                  to={"/rit-wijzigen/" + props.ride.id}
                  className="button-4 w-button"
                >
                  Wijzigen
                </NavLink>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </li>
  ) : (
      "Details worden nog geladen..."
    );
};

export default JoinedRitPreview;
