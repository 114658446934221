import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { NavLink } from 'react-router-dom';

// HOC
import Aux from "./../../hoc/Auxo/Auxo";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

class Homepage extends Component {
  state = {};

  render() {
    return (
      <Aux>
        <div className="section cc-store-home-wrap">
          <div className="intro-header">
            <div className="intro-content cc-homepage">
              <div className="intro-text">
                <div className="heading-jumbo">TGTR</div>
                <div className="paragraph-bigger cc-bigger-light">
                  Together - Rij wanneer het jou uitkomt, met wie het uitkomt
                  <br />
                </div>
              </div>
              <NavLink
                to="/ritten"
                className="button cc-jumbo-button w-inline-block"
              >
                <div>VIND RITTEN</div>
                </NavLink>
            </div>
          </div>
          <div className="container">
            <div className="motto-wrap">
              <div className="label cc-light">WIJ GELOVEN</div>
              <div className="heading-jumbo-small">
                Het plezier van het samen rijden met het gemak van je eigen
                agenda.
                <br />
              </div>
            </div>
            <div className="divider" />
            <div className="home-content-wrap">
              <div className="w-layout-grid about-grid">
                <div id="w-node-76c147234d34-185bfbd6">
                  <div className="home-section-wrap">
                    <div className="label cc-light">OVER ONS</div>
                    <h2 className="section-heading">Wie wij zijn</h2>
                    <p className="paragraph-light">
                      Nulla vel sodales tellus, quis condimentum enim. Nunc
                      porttitor venenatis feugiat. Etiam quis faucibus erat, non
                      accumsan leo. Aliquam erat volutpat. Vestibulum ac
                      faucibus eros. Cras ullamcorper gravida tellus ut
                      consequat.
                    </p>
                  </div>
                  <NavLink to="/over-ons" className="button w-inline-block">
                    <div>LEES MEER</div>
                  </NavLink>
                </div>
                <img
                  src="images/Wielrennen-in-Zeeland---cropped.png"
                  srcSet="
                images/Wielrennen-in-Zeeland---cropped-p-500.png  500w,
                images/Wielrennen-in-Zeeland---cropped-p-800.png  800w,
                images/Wielrennen-in-Zeeland---cropped.png        1199w
              "
                  sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 95vw"
                  id="w-node-76c147234d3f-185bfbd6"
                  alt=""
                  className="image"
                />
              </div>
              <div className="w-layout-grid about-grid cc-about-2">
                <div id="w-node-76c147234d41-185bfbd6">
                  <div className="home-section-wrap">
                    <div className="label cc-light">Team</div>
                    <h2 className="section-heading">Wat wij doen</h2>
                    <p className="paragraph-light">
                      Nulla vel sodales tellus, quis condimentum enim. Nunc
                      porttitor venenatis feugiat. Etiam quis faucibus erat, non
                      accumsan leo. Aliquam erat volutpat. Vestibulum ac
                      faucibus eros. Cras ullamcorper gravida tellus ut
                      consequat.
                    </p>
                  </div>
                  <NavLink to="/over-ons" className="button w-inline-block">
                    <div>LEES MEER</div>
                    </NavLink>
                </div>
                <img
                  src="images/Amateur-Wielrennen-png.png"
                  srcSet="
                images/Amateur-Wielrennen-png-p-500.png  500w,
                images/Amateur-Wielrennen-png-p-800.png  800w,
                images/Amateur-Wielrennen-png.png       1000w
              "
                  sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1063px) 94vw, 1000px"
                  id="w-node-76c147234d4c-185bfbd6"
                  alt=""
                  className="image-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="blog-heading">
              <div className="label cc-light">OVER ONS</div>
              <h2 className="work-heading">Laatste nieuws</h2>
            </div>
            <div className="collection-list-wrapper w-dyn-list">
              {this.props.blogs.length > 0 ? 
              this.props.blogs.map(blog => {
                return <div className="collection-wrap w-dyn-items">
                  <div className="blog-preview-wrap w-dyn-item">
                    <h1>{blog.title}</h1>
                    <div className="label cc-blog-date" />
                    <p className="paragraph-light">
                      {blog.description}
                    </p>
                  </div>
                </div>
              }) : (
                <div className="status-message cc-no-data w-dyn-empty">
                  <div>Geen blogs gevonden.</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    blogs: state.blogs.allBlogs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Homepage, axios));
