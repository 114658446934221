import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { ClipLoader } from 'react-spinners';

// HOC
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";

import './Auth.css';

const override = {
  'display': 'block',
  'margin': '0 auto',
  position: "fixed",
  'borderColor': 'red',
  transform: "translate(-50%, -50%)",
  top: "30%",
  left: "45%"
}

class Auth extends Component {
  state = {
    email: '',
    password: '',
    controls: {
      email: {
        elementtype: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementtype: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Wachtwoord'
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        touched: false
      }
    },
  }

  submitHandler = (event) => {
    event.preventDefault();

    this.props.onAuth(
      this.state.email,
      this.state.password,
      null,
      null,
      false
    )
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/mijn-ritten')
    }
  }

  changedCredentials = (e) => {
    if (e.target.name === 'email') {
      this.setState({ email: e.target.value })
    } else {
      this.setState({ password: e.target.value })
    }
  }

  render() {
    return (
      !this.props.isAuthenticated ?
        (<div>
          <div className="section">
            <div className="intro-header cc-subpage">
              <div className="intro-content">
                <div className="heading-jumbo">
                  Login
                <br />
                </div>
              </div>
            </div>
          </div>
          <div className="div-block">
            <h2 className="contact-heading">Heb je al een account?</h2>
            <div className="paragraph-light">
              Je kunt hier beneden inloggen om direct ritten bij jou in de buurt
              te vinden. Wanneer je ingelogd bent zullen er nieuwe menu items
              beschikbaar komen. Heb je nog geen account?
            <NavLink to="/register">
                Klik hier om een account aan te maken tijdens de bèta.
            </NavLink>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={'#123abc'}
                loading={this.props.loading}
              />
            </div>
            <div className="contact-htmlForm w-htmlForm">
              <div
                data-name="Get In Touch htmlForm"
                name="wf-htmlForm-Get-In-Touch-htmlForm"
                className="get-in-touch-htmlForm"
              >
                <label htmlFor="name">EMAIL</label>
                <input
                  type="text"
                  className="text-field cc-contact-field w-input"
                  maxLength="256"
                  name="email"
                  value={this.state.email}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.submitHandler(e);
                    }
                  }}
                  onChange={(e) => this.changedCredentials(e)}
                  placeholder="Vul je email in"
                  required=""
                />
                <label htmlFor="Email-2">WACHTWOORD</label>
                <input
                  type="password"
                  className="text-field cc-contact-field w-input"
                  maxLength="256"
                  value={this.state.password}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.submitHandler(e);
                    }
                  }}
                  onChange={(e) => this.changedCredentials(e)}
                  placeholder="Vul je wachtwoord in"
                  required=""
                />
                {this.props.error !== null ? <div className={'WarningMessage'}>Je e-mail en/of wachtwoord combinatie is niet correct.</div> : null}

                <input
                  type="submit"
                  value="LOGIN"
                  onClick={(e) => this.submitHandler(e)}
                  data-wait="Thinking..."
                  className="Button"
                />
              </div>
            </div>
          </div>
        </div>
        )
        : 'Welkom! Je bent succesvol ingelogd!'
    )
  }
}

const mapStateToProps = state => {
  return {
    currentlyLoading: state.ritten.currentlyLoading,
    blogs: state.blogs.allBlogs,
    loading: state.users.loading,
    error: state.users.error,
    isAuthenticated: state.users.token !== null,
    authRedirectPath: state.users.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetAllRides: () => dispatch(actions.GetAllRides()),
    onAuth: (email, password, naam, telefoonnummer, isSignup) => dispatch(actions.auth(email, password, naam, telefoonnummer, isSignup)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Auth, axios));

