import React from "react";

import { NavLink } from "react-router-dom";

const RitPreview = props => {
  return (
    <div className="div-block-2">
      <h1>{props.ride.name}</h1>
      <p>
        {props.ride.departure.date} - {props.ride.departure.time} -{" "}
        {props.ride.distance} km - {props.ride.averagePace} km/u
        <br />‍<br />
        - De rit vertrekt vanaf de {props.ride.departure.street} {props.ride.departure.streetNumber} in {props.ride.departure.city} en duurt ongeveer {props.ride.distance}
        kilometer. <br />
        - Het geschatte gemiddelde tempo is {props.ride.averagePace} kilometer per uur en het vertrek
        is {props.ride.departure.time}. <br />
        {props.ride.riders ? '- Er zijn op het moment ' + Object.keys(props.ride.riders).length + ' rijders aangemeld' : '- Er zijn nog geen rijders aangemeld'}
        <br />
      </p>
      <NavLink
        to={"/rit/" + props.ride.id}
        className="button-4 w-button">
        Open details van rit
      </NavLink>
    </div >
  );
};

export default RitPreview;
