import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  authenticated: false,
  token: null,
  userId: null,
  error: null,
  user: null,
  loading: false,
  publicProfileUser: null,
  ritWijzigen: {
    status: false,
    id: 0
  },
  users: {
    id1: {
      avatar: "default",
      name: "Jan Smit",
      score: 5,
      organisedHistory: [],
      ridesHistory: []
    }
  },
  registrationSuccess: false,
  userSearchResults: null
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const setEntryPoint = (state, action) => {
  return updateObject(state, { entryPoint: action.entryPoint });
};

const registrationSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    user: action.userMeta,
    loading: false,
    error: null,
    registrationSuccess: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    user: action.userMeta,
    loading: false,
    error: null
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    registrationSuccess: false
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path
  });
};

const userUpdateInit = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const userUpdateSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false
  });
};

const initGetUserProfile = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const getUserProfileSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    publicProfileUser: action.user
  });
};

const initGetFriendsDetails = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const getFriendsDetailsSuccess = (state, action) => {
  let user = { ...state.user };
  user["friends"] = action.friends;
  return updateObject(state, {
    loading: false,
    user: user
  });
};

const initInviteFriend = (state, action) => {
  return {
    ...state,
    loading: true
  };
};

const inviteFriendSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const initSearchUsersByEmail = (state, action) => {
  return {
    ...state,
    loading: true
  };
};

const searchUsersByEmailSuccess = (state, action) => {
  let foundUsers = [];
  if (action.foundUsersList !== null) {
    foundUsers = action.foundUsersList;
  }

  return {
    ...state,
    loading: false,
    userSearchResults: foundUsers
  };
};

const acceptFriendRequestSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    user: action.user
  }
}

const initAcceptFriendRequest = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.REGISTRATION_SUCCESS:
      return registrationSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.SET_ENTRY_POINT:
      return setEntryPoint(state, action);
    case actionTypes.USER_UPDATE_INIT:
      return userUpdateInit(state, action);
    case actionTypes.USER_UPDATE_SUCCESS:
      return userUpdateSuccess(state, action);
    case actionTypes.INIT_GET_USER:
      return initGetUserProfile(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserProfileSuccess(state, action);
    case actionTypes.INIT_GET_FRIENDS_DETAILS:
      return initGetFriendsDetails(state, action);
    case actionTypes.GET_FRIENDS_DETAILS_SUCCESS:
      return getFriendsDetailsSuccess(state, action);
    case actionTypes.INIT_INVITE_FRIEND:
      return initInviteFriend(state, action);
    case actionTypes.INVITE_FRIEND_SUCCESS:
      return inviteFriendSuccess(state, action);
    case actionTypes.INIT_SEARCH_USER_BY_EMAIL:
      return initSearchUsersByEmail(state, action);
    case actionTypes.SEARCH_USER_BY_EMAIL_SUCCESS:
      return searchUsersByEmailSuccess(state, action);
    case actionTypes.INIT_ACCEPT_FRIEND_REQUEST:
      return initAcceptFriendRequest(state, action);
    case actionTypes.ACCEPT_FRIEND_REQUEST_SUCCESS:
      return acceptFriendRequestSuccess(state, action);

    case actionTypes.AUTHENTICATE:
      return {
        ...state,
        authenticated: true
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        authenticated: false
      };

    case actionTypes.GET_ALL_USERS:
      return {
        ...state
      };

    default:
      return state;
  }
};

export default reducer;
