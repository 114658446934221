import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from '../../components/UI/Loader/Loader';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Avatar from './../../assets/images/boy.png';

// HOC
import * as actions from "../../store/actions/index";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import 'react-notifications/lib/notifications.css';

class MyProfile extends Component {
    state = {
        user: null
    }
    
    componentDidUpdate() {
        if (this.props.user !== null & this.state.user === null) {
            this.setState({ user: { ...this.props.user } })
        }
    }

    componentDidMount() {
        if (this.props.user !== null) {
            this.setState({ user: { ...this.props.user } })
        }
    }

    changeUserDetails = (e) => {
        let user = { ...this.state.user }

        if (e.target.name === 'zipcode' | e.target.name === 'city') {
            user.address[e.target.name] = e.target.value;
        } else if (e.target.name === 'mailsAboutRides' | e.target.name === 'newsletters') {
            user.contactPreferences[e.target.name] = !this.state.user.contactPreferences[e.target.name];
        } else {
            user[e.target.name] = e.target.value;
        }

        this.setState({
            user: user
        })
    }

    storeUserDetails = (e) => {
        e.preventDefault();
        this.props.updateUserProfile(this.state.user);
        NotificationManager.success('We hebben je profiel bijgewerkt', 'Succesvol geupdatet!');
    }

    getStarRating = (userScore) => {
        let CURRENT_SCORE_TEMP = 0;
        let stars = []
        while (CURRENT_SCORE_TEMP < Number(userScore)) {
            stars.push(
                <img
                    key={CURRENT_SCORE_TEMP}
                    src="images/star.png"
                    alt=""
                    className="image-9" />
            )
            CURRENT_SCORE_TEMP++;
        }

        return stars
    }

    render() {
        return (
            this.state.user !== null ? (
                <div className="section cc-home-wrap">
                    <div className="container">
                        <div className="motto-wrap">
                            <div className="heading-jumbo-small">Jouw profiel wijzigen</div>
                        </div>
                        <div className="divider"></div>
                        <div className="w-row">
                            <div className="w-col w-col-3">
                                <img src={Avatar} alt="" />
                                <div className="div-block-4">
                                    <a href="/login" className="button-5 w-button">Avatar wijzigen</a>
                                </div>
                            </div>
                            <div className="w-col w-col-9">
                                <div className="w-htmlForm">
                                    <div id="email-htmlForm" name="email-htmlForm" data-name="Email htmlForm">
                                        <h6>Over mij</h6>
                                        <textarea
                                            id="field-7"
                                            name="aboutme"
                                            onChange={this.changeUserDetails}
                                            value={this.state.user.aboutme}
                                            placeholder="Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. "
                                            maxLength="5000"
                                            className="w-input">
                                        </textarea>
                                    </div>
                                </div>
                                <h6>Wat de statistieken zeggen</h6>
                                <ul className="list-2">
                                    <li>
                                        <div className="w-htmlForm">
                                            <div id="email-htmlForm" name="email-htmlForm" data-name="Email htmlForm">
                                                <p className="paragraph-light profiellabel">
                                                    Gemiddelde afstand<br />
                                                </p>
                                                <input
                                                    onChange={this.changeUserDetails}
                                                    value={this.state.user.averageDistance}
                                                    type="number"
                                                    className="text-field-2 w-input"
                                                    maxLength="256"
                                                    name="averageDistance"
                                                    placeholder="40"
                                                    id="field" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="w-htmlForm">
                                            <div id="email-htmlForm" name="email-htmlForm" data-name="Email htmlForm">
                                                <p className="paragraph-light profiellabel">Gemiddelde snelheid (per uur)<br /></p>
                                                <input
                                                    value={this.state.user.averagePace}
                                                    onChange={this.changeUserDetails}
                                                    type="number"
                                                    className="text-field-2 w-input"
                                                    maxLength="256"
                                                    name="averagePace"
                                                    placeholder="40"
                                                    id="field-2" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="paragraph-light">{this.state.user.ridesHistory ? this.state.user.ridesHistory : 0} ritten gereden op TGTR<br /></p>
                                        <div className="w-clearfix">
                                            {this.state.user.score ? this.getStarRating(this.state.user.score) : null}
                                        </div>
                                    </li>
                                </ul>
                                <div className="w-htmlForm">
                                    <div id="email-htmlForm" name="email-htmlForm" data-name="Email htmlForm">
                                        <h6>Persoonsgegevens</h6>
                                        <div className="w-row">
                                            <div className="w-col w-col-6">
                                                <label>VOORNAAM</label>
                                                <input
                                                    onChange={this.changeUserDetails}
                                                    value={this.state.user.firstName}
                                                    type="text"
                                                    className="text-field-3 w-input"
                                                    maxLength="256"
                                                    name="firstName"
                                                    placeholder="Hendrik"
                                                    id="field-8" />
                                            </div>
                                            <div className="w-col w-col-6">
                                                <label>ACHTERNAAM</label>
                                                <input
                                                    onChange={this.changeUserDetails}
                                                    value={this.state.user.lastName}
                                                    type="text"
                                                    className="text-field-4 w-input"
                                                    maxLength="256"
                                                    name="lastName"
                                                    placeholder="Van Distel"
                                                    id="field-8" />
                                            </div>
                                        </div>
                                        <div className="w-row">
                                            <div className="w-col w-col-6">
                                                <label>POSTCODE</label>
                                                <input
                                                    onChange={this.changeUserDetails}
                                                    type="text"
                                                    value={this.state.user.address.zipcode}
                                                    className="text-field-3 w-input"
                                                    maxLength="256"
                                                    name="zipcode"
                                                    placeholder="3551 ET"
                                                    id="field-8" />
                                            </div>
                                            <div className="w-col w-col-6">
                                                <label>STAD</label>
                                                <input
                                                    type="text"
                                                    onChange={this.changeUserDetails}
                                                    value={this.state.user.address.city}
                                                    className="text-field-4 w-input"
                                                    maxLength="256"
                                                    name="city"
                                                    placeholder="Utrecht"
                                                    id="field-8" />
                                            </div>
                                        </div>
                                        <label htmlFor="strava">STRAVA PROFIEL</label>
                                        <input
                                            type="text"
                                            onChange={this.changeUserDetails}
                                            value={this.state.user.stravaprofile}
                                            className="w-input"
                                            maxLength="256"
                                            name="stravaprofile"
                                            placeholder="https://www.strava.com/user/hendrikvandistel"
                                            id="strava" />
                                        <label>MOBIELE NUMMER</label>
                                        <input
                                            type="tel"
                                            onChange={this.changeUserDetails}
                                            value={this.state.user.mobilephone}
                                            className="w-input"
                                            maxLength="256"
                                            name="mobilephone"
                                            placeholder="06 12 34 56 78"
                                            id="field-9" />
                                        <div className="w-checkbox">
                                            <input
                                                type="checkbox"
                                                onChange={this.changeUserDetails}
                                                checked={this.state.user.contactPreferences.mailsAboutRides}
                                                id="checkbox"
                                                name="mailsAboutRides"
                                                className="w-checkbox-input" />
                                            <label htmlFor="checkbox" className="w-htmlForm-label">
                                                IK WIL GRAAG MAILTJES ONTVANGEN MET RITTEN BIJ MIJ IN DE BUURT.
                                            </label>
                                        </div>
                                        <div className="w-checkbox">
                                            <input
                                                type="checkbox"
                                                id="checkbox-2"
                                                name="newsletters"
                                                onChange={this.changeUserDetails}
                                                checked={this.state.user.contactPreferences.newsletters}
                                                className="w-checkbox-input" />
                                            <label htmlFor="checkbox-2" className="w-htmlForm-label">
                                                IK WIL GRAAG SPORADISCH NIEUWSBR/IEVEN ONTVANGEN MET DE LAATSTE VERHALEN VAN MEDERIJDERS.
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <NotificationContainer />
                                        <button onClick={(e) => this.storeUserDetails(e)} className="button-3 w-button">
                                            OPSLAAN
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-story-wrap"></div>
                        <div className="divider"></div>
                    </div>
                </div>
            ) :
                this.props.loading ? <Loader /> :
                    (
                        <div style={{ textAlign: 'center', minHeight: 400 }}>
                            Er is iets fout gegaan bij het ophalen van je gegevens. Refresh deze pagina om het nog een keer te proberen.
                            </div>
                    )
        );
    }
}

const mapStateToProps = state => {
    return {
        currentlyLoading: state.ritten.currentlyLoading,
        blogs: state.blogs.allBlogs,
        loading: state.users.loading,
        user: state.users.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GetAllRides: () => dispatch(actions.GetAllRides()),
        updateUserProfile: (user) => dispatch(actions.updateUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MyProfile, axios));

