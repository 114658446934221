import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

// HOC
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";

import FriendRitPreview from './../../../components/Rit/FriendRitPreview/FriendRitPreview';

class RittenVrienden extends Component {
    state = {
        finishedSearchingFriendsRides: false,
        userHasFriends: false
    }
    componentDidMount() {
        this.props.GetAllRides();
    }

    componentDidUpdate() {
        if (this.state.finishedSearchingFriendsRides === false & this.props.allRides !== null & this.props.user !== null) {
            if (this.props.user.friends !== undefined) {
                this.setState({
                    finishedSearchingFriendsRides: true,
                    userHasFriends: true
                })
            } else {
                // Do something
                this.setState({
                    finishedSearchingFriendsRides: true
                })
            }
        }
    }

    render() {
        let ridesFromFriends = [];

        if (this.state.finishedSearchingFriendsRides & this.props.allRides !== null & this.props.user !== null) {
            if (this.state.userHasFriends) {
                Object.keys(this.props.allRides).forEach(rideId => {
                    let ride = { ...this.props.allRides[rideId] };
                    let user = { ...this.props.user };
                    if (ride.riders !== undefined) {
                        // Check which friends are attending
                        Object.keys(ride.riders).forEach(userId => {
                            if (userId in user.friends) {
                                ridesFromFriends.push(
                                    {
                                        user: ride.riders[userId],
                                        ride: ride,
                                        type: 'attendee'
                                    }
                                )
                            }
                        })

                        // Check if friend might be hosting
                        if (ride.host.id in user.friends) {
                            ridesFromFriends.push(
                                {
                                    user: ride.host,
                                    ride: ride,
                                    type: 'host'
                                }
                            )
                        }
                    } else {
                        // Check if friend might be hosting
                        if (ride.host.id in user.friends) {
                            ridesFromFriends.push(
                                {
                                    user: ride.host,
                                    ride: ride,
                                    type: 'host'
                                }
                            )
                        }
                    }
                })
            }
        }

        return (
            <div className="container">
                <div className="motto-wrap">
                    <div className="heading-jumbo-small">Ritten van vrienden</div>
                    <p className="paragraph-light" style={{ textAlign: 'center' }}>
                        Dit zijn de ritten die jouw vrienden binnen kort rijden. <br />
                        Je kunt meedoen door op de groene knop naast een naam en rit te klikken.
                    </p>
                </div>
                <div className="divider"></div>
                <ul className="list">
                    <li>
                        {ridesFromFriends.length > 0 ?
                            ridesFromFriends.map( (friendsRide, index) => {
                                return <FriendRitPreview key={index} friendsRide={friendsRide} user={this.props.user} />
                            }) : 'Geen ritten van vrienden gevonden...'}
                    </li>
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        allRides: state.ritten.currentRides,
        user: state.users.user,
        currentlyLoading: state.ritten.currentlyLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GetAllRides: () => dispatch(actions.GetAllRides())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(RittenVrienden, axios));
