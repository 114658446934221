import React from 'react';
import { NavLink } from "react-router-dom";

const LogoutSuccess = props => {
    return (
        <div className="section">
            <div className="intro-header cc-subpage">
                <div className="intro-content">
                    <div className="heading-jumbo">
                        Tot ziens
                        <br />
                    </div>
                </div>
            </div>

            <div className="div-block">
                <h2 className="contact-heading">Je kunt altijd weer opnieuw inloggen.</h2>
                <div className="paragraph-light">
                    Je kunt pas weer ritten zien wanneer je weer opnieuw inlogt.
                    <NavLink to="/">Klik hier om terug te gaan naar de homepagina</NavLink>
                </div>
            </div>
        </div>
    )
}

export default LogoutSuccess;